import React, { useEffect, useState } from 'react'
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Progress } from '@manulife/mux';
import { getQueryParams } from '../../utils';
import resendMultilifeInvite from '../../api/resendMultilifeInvite';
import EncounteredError from '../../components/EncounteredError/EncounteredError';
import JsonConfig from '../../utils/jsonConfig.json';


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";

const useStyles = makeStyles((theme) => ({

    linkExpiryRoot: {
        width: "100%",
    },

    linkExpiryText: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "44px",
        lineHeight: "58px",
        color: "#202336",
    },

    linkExpiryGridSeparation: {
        paddingBottom: "32px"
    },
    gridSeparation: {
        paddingBottom: "32px",
        marginBottom: "40px"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px"
    },
    inlineDisplay2: {
        display: displayStyle,
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: displayStyle,
        marginLeft: "15px"
    },
    ctaWrapper: {

        "& button": {
            width: "175px",
            height: "55px",
            minWidth: "0px",
            fontWeight: "500",
            fontFamily: font,
            fontSize: "18px",
            lineHeight: "22px",
            fontStyle: "normal",
            textAlign: "center",
            color: "#FFFFFF",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
}));

let { jwt } = getQueryParams();

const LinkExpiredPage = (props) => {

    let { setDisplaySuccessInvitePage, systemErrorCount, setSystemErrorCount, setErrorScenario, setLinkExpired, appId } = props;
    const classes = useStyles();

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        props.setSessionTimeoutEnable(false)
    }, [])

    function onResponse(response) {
        setLoading(false);
        let code = response.code;
        if (code === "2000") {
            // Success show congratulation page
            setDisplaySuccessInvitePage(true);
            setLinkExpired(false);
        } else if (code === "9009" || code === "9007" || code === "9021" || code === "5006") {
            setErrorScenario(true);
            setLinkExpired(false);
        } else if (code === "9999") {
            setSystemErrorCount(systemErrorCount + 1);
        } else {
            setSystemErrorCount(systemErrorCount + 1);
        }
    }

    function validateAndResendLink() {
        if (jwt) {
            setLoading(true);
            resendMultilifeInvite(onResponse);
        }
    }

    const redirectToLogin = appId => { 
        if (appId === JsonConfig.app_id.PDCO) {
            window.location.href = process.env.REACT_APP_LOGIN_URL_PDCO;
        }
        else
            window.location.href = "https://customer.johnhancock.com";
    }


    

    return (
        <div data-testid="link-expiry-root" className={classes.linkExpiryRoot}>
            {
                props.systemErrorCount > 0 &&
                <EncounteredError systemErrorCount={systemErrorCount} />
            }
            <Grid item md={12} xs={12} className={classes.linkExpiryGridSeparation}>
                <div className={classes.linkExpiryText}>Your registration link has expired.</div>
            </Grid>
            {appId !== JsonConfig.app_id.PDCO && (
                <>
                    {/* <Grid container md={8} xs={12} className={classes.gridSeparation}>
                        <span className={classes.subheaderStyles}>Please sign in to access your account</span>
                    </Grid> */}
                    <Grid item md={12} xs={12} className={classes.ctaWrapper}>
                        <Button
                            name="getNewLinkButton"
                            data-testid="link-expiry-getNewLinkButton"
                            id="getNewLinkButton"
                            onClick={e => redirectToLogin()}
                            disabled={isLoading}
                            saving={isLoading}
                        >Sign In</Button>
                    </Grid>
                </>
            )}
        </div>
    );
}

export default LinkExpiredPage;
