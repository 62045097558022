import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ReactComponent as FooterLogo } from "../../images/Logo Manulife_John_Hancock_Wealth.svg";
import JsonConfig from "../../utils/jsonConfig.json";
import { getQueryParams } from "../../utils";
import { ReactComponent as Facebook } from "../../images/facebook.svg";
import { ReactComponent as YouTube } from "../../images/youtube.svg";
import { ReactComponent as Twitter } from "../../images/twitter.svg";
import { ReactComponent as Instagram } from "../../images/instagram.svg";
import { ReactComponent as LinkedIn } from "../../images/linkedin.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0, // for the sticky footer
    width: "100%",
    background: "#fff",
    marginTop: "100px",
  },
  footer: {
    width: "100%",
    background: "#2F3242",
  },
  bodyContainer: {
    padding: "32px 0px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  logoStyles: {
    textAlign: "left",

    [theme.breakpoints.down("sm")]: {
      marginBottom: "24px",
    },
  },
  bodyStyles: {
    padding: "0 30px 30px",
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },
  labelStyles: {
    margin: 0,
    fontWeight: "400",
    fontFamily: "Manulife JH Sans",
    fontSize: "13px",
    display: "block",
    color: "#FFFFFF",
    lineHeight: "16px",
    opacity: "0.8",
    "& p": {
      marginBottom: "16px",
    },
  },
  gridSeparation: {
    paddingBottom: "25px",
  },
  linkStyles: {
    color: "#FFFFFF",
    textDecoration: "none",
    fontFamily: "Manulife JH Sans",
    fontSize: "14px",
    whiteSpace: "nowrap",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkListContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    listStyle: "none",
    padding: 0,
    margin: 0,
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",
    },

    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      padding: "16px 0 16px 0",
    },

    "& li": {
      [theme.breakpoints.down("xs")]: {
        paddingTop: "16px",
        paddingBottom: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "16px",
        paddingBottom: "16px",
      },
      [theme.breakpoints.down("lg")]: {
        paddingTop: "16px",
        paddingBottom: "16px",
      },
      "& a": {
        marginRight: "32px",
      },
      "&:last-child:after": {},
    },
  },
  contactUsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    listStyle: "none",
    padding: 0,
    margin: 0,
    alignItems: "center",
    fontSize: "14px",
    gap: "8px",
  },
  iconStylesGrey: {
    fontSize: "14px",
    color: "#5E6073",
    borderRadius: "50%",
    border: "1px solid #5E6073",
    height: "1.5rem",
    width: "1.5rem",
    padding: "8px",
  },
  newLine: {
    display: "block",
  },
  footerMenuContainer: {
    padding: "32px 40px 32px 40px",
    borderBottom: "2px solid #34384B",

    [theme.breakpoints.down("xs")]: {
      padding: "32px 20px 32px 20px",
    },
  },
  copyrightLabels: {
    fontWeight: 400,
    fontSize: "13px",
    color: "#DDDEE4",
    height: "16px"
    }
  ,
}));

const DTCFooter = (props) => {
  const classes = useStyles();

  let { appId } = getQueryParams();

  return (
    <Grid data-testid="footer-root" className={classes.root}>
      <Grid className={classes.footer}>
        {appId !== JsonConfig.app_id.DTC &&
          process.env.REACT_APP_ENABLE_CHATBOT === "enabled" && (
            <div className="jh-webchat" id="chatBotDiv" />
          )}
        <Grid
          container
          justifyContent="space-between"
          className={classes.footerMenuContainer}
        >
          <Grid item md={6} xs={12} style={{ display: "flex", alignItems: "center" }}>
            <ul className={classes.linkListContainer}>
              <li>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://digital.customer.johnhancock.com/help/contact"
                  target="_blank"
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://www.johnhancock.com/accessibility.html"
                  target="_blank"
                >
                  Accessibility
                </a>
              </li>
              <li>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://www.johnhancock.com/privacy-security.html"
                  target="_blank"
                >
                  Privacy &amp; Security
                </a>
              </li>
              <li>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://www.johnhancock.com/legal.html"
                  target="_blank"
                >
                  Terms of Services
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item md={6} xs={12}>
            <ul className={classes.contactUsContainer}>
              <li id='facebook'>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/johnhancock/"
                  target="_blank"
                >
                  <Facebook />
                </a>
              </li>
              <li id='youtube'>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCjLx1uO0Rhi1H00BFDpvshw"
                  target="_blank"
                >
                  <YouTube />
                </a>
              </li>
              <li id='twitter'>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://x.com/johnhancockusa?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  target="_blank"
                >
                  <Twitter />
                </a>
              </li>
              <li id='instagram'>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/johnhancock/"
                  target="_blank"
                >
                  <Instagram />
                </a>
              </li>
              <li id='linkedin'>
                <a
                  className={classes.linkStyles}
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/john-hancock/"
                  target="_blank"
                >
                  <LinkedIn />
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container className={classes.footerMenuContainer}>
          {
            <div className={classes.labelStyles} id="footer-paragraph">
              <p>
                If you need assistance registering or already have an online
                account with us, please call 1-800-225-5291, Monday - Friday
                8:00AM - 6:00PM EST.
              </p>
              <p>
                Manulife, Manulife Wealth, Stylized M Design, and Manulife
                Wealth & Stylized M Design are trademarks of The Manufacturers
                Life Insurance Company, and John Hancock and the Stylized John
                Hancock Design are trademarks of John Hancock Life Insurance
                Company (U.S.A.). Each are used by it and by its affiliates
                under license.
              </p>
            </div>
          }
          <Grid
            justifyContent="space-between"
            container
            spacing={0}
            className={classes.bodyContainer}
          >
            <Grid item md={6} xs={12} className={classes.logoStyles}>
              <FooterLogo />
            </Grid>
            <Grid item md={6} xs={12}>
              <p className={classes.copyrightLabels}>© 2025 Manulife John Hancock Wealth. All rights reserved.</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DTCFooter;
