export function isBlank(item) {
  return item === "";
}

export function isNull(item) {
  return item == null;
}
export function doNothing() {
  // This function is used to prevent input field crash on keypress.
}

export function redirectUrl(url) {
  window.location.href = url;
}

function sanitize(string) {
  if (string == null) return string;

  const createDOMPurify = require("dompurify");
  const DOMPurify = createDOMPurify(window);
  string = DOMPurify.sanitize(string);
  return string;
}
export function formatedPhoneNumber(phoneNumber) {
  if (phoneNumber && phoneNumber.length >= 10) {
    let mPhoneNumber = phoneNumber?.trim();
    //This is the outpit (999) 999-9999
    mPhoneNumber =
      "(" +
      mPhoneNumber.substring(0, 3) +
      ") " +
      mPhoneNumber.substring(3, 6) +
      "-" +
      mPhoneNumber.substring(6, 10);
    return mPhoneNumber;
  }
  return phoneNumber;
}
export function getPhoneNumberLabel(phoneNumber, countryCode=1) {
  console.log("createLabelText " + countryCode);
  let phoneNum = phoneNumber?.replace(/[^\w]/gi, "");
  if (phoneNum?.length < 4) {
    phoneNum = "XXXX";
  }
  phoneNum = "+" + countryCode + " XXX-XXX-" + phoneNum.slice(-4);
  return phoneNum;
}
export function scrollToTop() {
  let ele = document.getElementById("header-root");
  ele?.scrollIntoView();
}

export async function hashSHA256(stringInput) {
  // create a TextEncoder instance, used to encode string to Unit8Array (byte array)
  const encoder = new TextEncoder();

  // encode string to Unit8Array
  const encoded = encoder.encode(stringInput);

  // use the subtleCrypto api to generate SHA-256 hash for the encoded variable
  const hashBuffer = await crypto.subtle.digest("SHA-256", encoded);

  // convert ArrayBuffer to a Uint8Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // map each byte into Uint8Array to a hexaDecimal string and ensuring each byte is 2 characters long
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
}
export function phoneNumberParser(phone) {
  console.log(phone);
  let nationalPhoneNumber = phone;
  let countryCode ;
  let countryType = "US";
  if (phone.includes("(") && phone.includes(")")) {
    const tempArr = phone.split(")");
    nationalPhoneNumber = tempArr[1].replace(/[^\w]/gi, "");
    countryCode = tempArr[0].replace(/[^\w]/gi, "");
    countryType="";
  }

  return { nationalPhoneNumber, countryCode, countryType };
}

export function formatPhoneNumberWithCountryCode(countryCode, nationalPhoneNumber){
  if(!countryCode){
    return nationalPhoneNumber;
  }
  return "("+countryCode+")"+nationalPhoneNumber;
}
const Utility = {
  isBlank,
  isNull,
  doNothing,
  redirectUrl,
  sanitize,
  formatedPhoneNumber,
  getPhoneNumberLabel,
  scrollToTop,
  hashSHA256,
  phoneNumberParser,
  formatPhoneNumberWithCountryCode
};

export default Utility;
