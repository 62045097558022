import React, { useEffect, useState } from "react";
import {
    RadioButton,
    Tooltip,
    Button
} from '@manulife/mux';
import { Grid, makeStyles } from "@material-ui/core";
import InputError from "../../components/InputError/InputError";
import { validate } from "../../components/PageBody/validate";
import { Modal, BREAKPOINTS } from '@manulife/mux';
import { ElectronicTCModalPage } from '..';
import Utility from "../../utils/utility";

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const gridSeparation1 = "gridSeparation1";
const myinput = "myinput";
const useStyles = makeStyles((theme) => ({
    gridLowSeperation: {
        paddingBottom: "10px"
    },
    goPaperlessBody: {
        width: "100%"
    },
    goPaperlessContentBody: {
        fontFamily: font,
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px"
    },
    goPaperLessContent: {
        marginBottom: "40px",
        width: "100%"
    },
    termsAndConditions: {
        display: "flex",
        color: "#282B3E",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26px",
        alignItems: "center",
        paddingBottom: "16px"
    },
    ctaWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingBottom: "40px",
        marginTop: "20px",
        "& button": {
            width: "150px",
            minWidth: "145px",
            height: "60px",
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    }
}));

const SetPreferencesPage = props => {

    const { formValues, setFormValues, errorState, setErrorState, onClick, isAwaitingApiCall, paperlessOrigin, isPaperlessTCModalOpen, setPaperlessTCModalOpen,setSessionTimeoutEnable } = props;

    //add the error handling for the paperless preference
    const [errorMessage, setErrorMessage] = useState("");
    const [isUnderstand, setisUnderstand] = useState(false)
    useEffect(()=> {
        setSessionTimeoutEnable(true);
        Utility.scrollToTop();
    }, [])

    const changeHandler = (name, value) => {
        setErrorMessage("");
        let newFormValues = null;
        if (name === 'goPaperless') {
            newFormValues = { ...props.formValues, [name]: value, "notGoPaperless": false };
        }
        else if (name === 'notGoPaperless') {
            newFormValues = { ...props.formValues, [name]: value, "goPaperless": false };
        }

        setFormValues(newFormValues)
        setErrorState(validate(newFormValues, name, errorState, false, 6, null, true));
    }

    const checkisUnderstand = (value) => {
        setisUnderstand(true);
        setPaperlessTCModalOpen(false);
    };

    const classes = useStyles();

    const paperlessModalOpen = (event) => {
        event && event.preventDefault()
        setPaperlessTCModalOpen(true);
    }

    const paperlessModalClose = () => {
        setPaperlessTCModalOpen(false);
    };

    const selectApiCall = () => {
        // if (!formValues.goPaperless && !formValues.notGoPaperless) {
        //     console.log("setpreference")
        //     // Optionally, you can set an error state here to show a message to the user
        //    // setErrorMessage("Please select an option before proceeding.");
        //     setErrorState(validate(formValues, "goPaperless", errorState, true, 6, null, false));
        //     return;
        // }

        if (paperlessOrigin === "registration") {
            onClick(6, formValues.notGoPaperless || formValues.goPaperless)
        }
        else if (paperlessOrigin === "authentication") {
            onClick(7, formValues.notGoPaperless || formValues.goPaperless)
        }
    }

    return (
        <div>
            <Grid item md={12} xs={12}>
                <div className={classes.goPaperlessBody}>
                    <Grid className={[gridSeparation1 + " " + myinput + ((errorState.goPaperless.isError || errorState.notGoPaperless.isError) ? " error" : "")]}>
                        {/* {errorMessage && <div style={{ color: "#A00E18", paddingBottom: "40px" }}>{errorMessage}</div>} */}

                        <div className={[(classes.termsAndConditions)]} style={{ paddingBottom: "40px" }}>
                            <RadioButton
                                id="goPaperless"
                                data-testid="UserInfo-goPaperless"
                                required={true}
                                customStyle={{
                                }}
                                checked={formValues.goPaperless}
                                errors={[errorState.goPaperless]}
                                onChange={e => { changeHandler("goPaperless", e); }}
                                name="goPaperless"
                            />
                            <div className={classes.waiverText}><label htmlFor='goPaperless'>Yes, go paperless</label></div>
                        </div>
                        <div className={[(classes.termsAndConditions)]}>
                            <RadioButton
                                id="notGoPaperless"
                                data-testid="UserInfo-notGoPaperless"
                                required={true}
                                customStyle={{
                                }}
                                checked={props.formValues.notGoPaperless}
                                errors={[errorState.notGoPaperless]}
                                onChange={e => { changeHandler("notGoPaperless", e) }}
                                name="notGoPaperless"
                            />
                            <div className={classes.waiverText}><label htmlFor='notGoPaperless'>No, I prefer to receive paper mail</label></div>
                        </div>
                        {
                            (errorState.goPaperless.isError || errorState.notGoPaperless.isError) &&
                            <InputError errorState={errorState} inputId="goPaperless" showIcon={true} />
                        }
                    </Grid>
                    <Grid item md={12}>
                        <div className={classes.goPaperlessContentBody}>
                            <div className={classes.goPaperLessContent}>By selecting ‘Yes, go paperless’ you agree to receive emails with hyperlinks and files from John Hancock when a communication is delivered to your account, and confirm that you have read, understand, and agree to  the <a className={classes.blueUnderline} rel="noopener noreferrer" href="#" onClick={(event) => paperlessModalOpen(event)} target="_self" >Terms & Conditions of Electronic Delivery</a>.</div>
                            <div className={classes.goPaperLessContent}>This applies to all communications, notices, and disclosures related to all John Hancock life and/or long–term care insurance policies you own, except tax documents and Vitality communications. If you would like to make changes or customize your paperless elections, you can update your settings by visiting the preferences page for each life and/or long–term care insurance policy after logging into your account.</div>
                        </div>
                    </Grid>


                    <div className={classes.ctaWrapper}>
                        <Button
                            onClick={() => selectApiCall()}
                            name="RegisterButton"
                            id="RegisterButton"
                            saving={isAwaitingApiCall}
                            disabled={isAwaitingApiCall}
                        >Continue</Button>
                    </div>
                </div>
            </Grid >
            <Modal isOpen={props.isPaperlessTCModalOpen} onClose={paperlessModalClose} customStyle={{
                modalStyle: {
                    height: 'auto',
                    width: '720px',
                    media: [{
                        maximum: BREAKPOINTS.PHONE_PORTRAIT_MED,
                        width: '85%'
                    }]
                }
            }} ariaLabel="Timeout Modal" ariaLabelledBy="heading" ariaDescribedBy="content">
                <ElectronicTCModalPage />
                <Button id="IUnderstandButton"
                    data-testid="UserInfo-IUnderstandButton"
                    name="IUnderstandButton"
                    customStyle={{
                        buttonStyle: {
                            width: "150px",
                            height: "60px"
                        }
                    }} disabled={props.isAwaitingApiCall} onClick={e => checkisUnderstand(e)}><div className={classes.confirmTextStyle}>I understand</div></Button>

            </Modal>
        </div >
    )
}

export default SetPreferencesPage;