import axios from 'axios';
import JsonConfig from '../utils/jsonConfig.json';
import { getQueryParams } from '../utils';
import Utility, { hashSHA256 } from '../utils/utility';


function validateMfa(phoneNumber, mfaCode, setMfaCode, systemErrorHook, guid, setErrorScenario, setIsAwaitingApiCall, mfaValidationMaxAttemptState, setErrorType, setProductList, clientIpAddress, setAlreadyRegistered, setRegistrationSuccess, setMfaValidationFailed, adobeDataLayer, setRedirectUrl, formValues,countryCode,nationalPhoneNumber,setReview) {
  
  let { appId, jwt } = getQueryParams();

  const setMfaValidationMaxAttempt = mfaValidationMaxAttemptState[1];
  const [systemErrorCount, setSystemErrorCount] = systemErrorHook;
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true,
    "m-device-print": window.DeviceRequest?.devicePrint,
    "m-user-agent": window.DeviceRequest?.userAgent,
    "m-client-address": clientIpAddress
  };
  console.log("isDOMContentLoaded==", window.isDOMContentLoaded);
  setIsAwaitingApiCall(true)
  const formatedPhoneNumber=Utility.formatPhoneNumberWithCountryCode(countryCode, nationalPhoneNumber);
  axios.post(
    process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/validateMFA` : "http://localhost:8080/validateMFA",
    {
      "PhoneNumber": formatedPhoneNumber,
      "Code": mfaCode,
      "Guid": guid,
      "Token": (appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY || appId === JsonConfig.app_id.PDCO) && jwt ? jwt : ""      
    },
    { headers }).then(response => {
      setIsAwaitingApiCall(false)
      
      const systemErrorCodes = ["9015", "9023", "9024", "9052"];
      const errorScenarioCodes = ["9046", "9069", "9047", "9037", "9038", "9016", "9017"];

      const responseCode = response.data.code;
      const responseMessage = response.data.message;
      const somethingWrong = 'something-went-wrong';
      const alreadyRegistered = 'already-registered';
      const mfaValidationFailed = 'MFA-validation-failed';
      const mfaMaxLimit = 'maximum-attempts-reached-MFA';

      if (responseCode === "200" || responseCode === "4000") {
        setProductList(response.data.details?.identity);
        setRedirectUrl(response.data.details.postUrl);
        setRegistrationSuccess(true);
        regSuccessAdobeHelper(adobeDataLayer, formValues);
      } else if (responseCode === "9000") {
        setMfaValidationMaxAttempt(true);
        adobeDataLayer.registrationUnsuccessfull(mfaMaxLimit, responseCode, responseMessage); 
      } else if(responseCode === "9004") {
        setMfaCode("")
        setMfaValidationFailed(true);
        adobeDataLayer.registrationUnsuccessfull(mfaValidationFailed, responseCode, responseMessage); 
      }else if (responseCode === "9063"){
        setReview(true);
        setErrorScenario(true)
      } else if (responseCode === "9056") {
        adobeDataLayer.registrationUnsuccessfull(somethingWrong, responseCode, responseMessage); 
        let url = response.data.details;
        if(url?.startsWith("https://crverifyidentity.registration-dev.johnhancock.com") || 
            url?.startsWith("https://crverifyidentity.registration-tst.johnhancock.com") ||
            url?.startsWith("https://crverifyidentity.registration-uat.johnhancock.com") ||
            url?.startsWith("https://crverifyidentity.registration.johnhancock.com") ||
            url?.startsWith("http://localhost:3001")){
            window.open(url, "_self");
        }
    } else if (errorScenarioCodes.includes(responseCode)) {
      setErrorScenario(true);
    } else if (responseCode === "9035") {
      setAlreadyRegistered(true);
      adobeDataLayer.registrationUnsuccessfull(alreadyRegistered, responseCode, responseMessage); 
    } else if (systemErrorCodes.includes(responseCode)) {
      setMfaCode("")
      setSystemErrorCount(systemErrorCount + 1);
      adobeDataLayer.registrationUnsuccessfull(somethingWrong, responseCode, responseMessage);
    } else{
      setMfaCode("")
      setSystemErrorCount(systemErrorCount + 1);
      adobeDataLayer.registrationUnsuccessfull(somethingWrong, responseCode, responseMessage);
    }

    }).catch(error => {
      setIsAwaitingApiCall(false)
      console.log("Error ========>", error);
      
      if (error.message === "Network Error") {
        setErrorType("Technical")
      } else{
        setSystemErrorCount(systemErrorCount + 1)
      }
    });
}

const regSuccessAdobeHelper = async(adobeDataLayer, formValues) => {
  const hashedUsername = await hashSHA256(formValues.userName);
  adobeDataLayer.registrationComplete(hashedUsername);
}

export default validateMfa;
