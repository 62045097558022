import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Button, Progress} from '@manulife/mux';
import Utility, { redirectUrl } from '../../utils/utility';
import JsonConfig from '../../utils/jsonConfig.json'
import Images from '../../utils/Images';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    leftSectionContainer: {
        paddingRight: "60px",
        
        [theme.breakpoints.down("sm")]: {
            paddingRight: "0"
        },

    },
    contentContainer: {
        paddingBottom: "32px",
        paddingTop: "0 !important"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    loginButtonContainer: {

        "& button": {
            height: "60px",
            fontFamily: font,

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    loginHeaderStyles: {
        fontWeight: "600",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "60px",
        color: "#202336",
        margin: 0,
        [theme.breakpoints.down("xs")]: {
            fontSize: "38px",
            lineHeight: "50px",
        }
    },
    loginBodyStyles: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "24px",
        lineHeight: "32px",
        color: "#202336",
        margin: 0,
        [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
        }
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom:"20px",
        display:"inline-block",
        marginLeft:"8px"
    },
    inlineDisplay: {
        display: "flex",
        height:"100%",
        marginLeft:"8px"
    },
    inlineDisplay2:{
        display: "inline-block",
        marginTop: "10px",
        marginBottom:"10px",
    },
    userNotesHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    buttonWrapper: {
        display:"flex",

        "& button": {
            width: "250px",
            height: "60px",
            marginTop: "-7px",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    userNotesStyles: {
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E",
    },
    userNotesSeparation: {
        marginTop: "16px",
        marginLeft: "10px",
        border: "1px solid #EDEDED",
        boxSizing: "border-box",
        [theme.breakpoints.down(1170)]: {
            marginLeft: "25px",
            marginRight: "25px"
        },
    },
    qrcodeRootContainer: {
        width: "auto",
        minWidth: "210px",
        backgroundColor: "#FAFAFA",
        padding: "32px",

        [theme.breakpoints.down("sm")]: {
            marginTop: "40px",
        }
    },    
    qrCodeTitle: {
        display: "block",
        fontFamily: font,
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "25px",
        color: "#202336",
        marginBottom: "16px"
    },    
    qrCodeBodyText1: {
        display: "block",
        fontFamily: font,
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#282B3E",
        marginBottom: "32px",

        [theme.breakpoints.only("xs")]: {
            display: "none"
        }
    },  
    qrCodeImage: {
        display: "block",
        width: "75%",
        maxWidth: "200px",
        minWidth: "150px",
        marginBottom: "32px",

        [theme.breakpoints.down("xs")]: {
            display: "none",
            width: "200px",
        }
    },
    storeImage: {
        display: "block",
        height: "47px",
        marginBottom: "16px",
        cursor: "pointer",
        [theme.breakpoints.down("1100")]: {
            height: "42px",
        },

        [theme.breakpoints.up("xs")]:{
            display: "none",
        },
        
        [theme.breakpoints.only("xs")]:{
            display: "block",
        }
    },
    qrCodeBodyText2: {
        display: "block",
        fontFamily: font,
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#282B3E",
        marginBottom: "16px",

        [theme.breakpoints.up("xs")]:{
            display: "none",
        },
        
        [theme.breakpoints.only("xs")]:{
            display: "block",
        }
    },
}))

const QRCodeComponent = ({classes})=> {
    return <div className={classes.qrcodeRootContainer}>
        <span className={classes.qrCodeTitle}>Download the John Hancock Vitality app</span>
        <span className={classes.qrCodeBodyText1}>On your desktop? Scan the QR code using your mobile device's camera.</span>
        <img className={classes.qrCodeImage} src={Images.vitalityQRCode} alt='Download the John Hancock Vitality app'/>
        <span className={classes.qrCodeBodyText2}>On your phone? Available on the App Store and Google Play.</span>
        <a target='_blank' rel="noopener noreferrer" href="https://apps.apple.com/us/app/john-hancock-vitality/id982822533"><img className={classes.storeImage} src={Images.VitalityAppStoreLogo} alt='Apple Store'/></a>
        <a target='_blank' rel="noopener noreferrer" href="https://play.google.com/store/apps/details/John_Hancock_Vitality?id=com.thevitalitygroup.johnhancock"><img className={classes.storeImage} src={Images.VitalityPlayStoreLogo} alt='Play Store'/></a>
    </div>
}

const RegisteredPage = props => {

    const classes = useStyles()
    const [productList, setProductList] = useState([]);

    useEffect(()=> {
        props.setSessionTimeoutEnable(true);
        Utility.scrollToTop();
    }, [])

    useEffect(()=> {

        if(props.productList && props.productList.length > 0){
            let tempList = [...props.productList];
            tempList.sort((a, b) => a.localeCompare(b))
            setProductList(tempList);
        } else{
            setProductList([]);
        }
    }, [props.productList])

    const redirectToLogin = appId => {
        let signInUrl = "";

        switch (appId) {
            case JsonConfig.app_id.DTC:
                signInUrl=process.env.REACT_APP_LOGIN_URL_DTC;
                break;
            case JsonConfig.app_id.LTC:
                signInUrl=process.env.REACT_APP_LOGIN_URL_LTC;
                break;
            case JsonConfig.app_id.LIFE:
            case JsonConfig.app_id.MultiLife:
                signInUrl=process.env.REACT_APP_LOGIN_URL_LIFE;
                break;                
            case JsonConfig.app_id.PDCO:
                signInUrl=process.env.REACT_APP_LOGIN_URL_PDCO;
                break;
            case JsonConfig.app_id.VITALITY:
                signInUrl=process.env.REACT_APP_LOGIN_URL_VITALITY;
                break;       
            default:
                signInUrl=process.env.REACT_APP_LOGIN_URL_DTC;
                break;
        }
        
        redirectUrl(signInUrl)
    }


    return (
        <Grid container justifyContent={"space-between"} className={classes.root}>
            <Grid item xs={12} sm={10} md={8} className={classes.leftSectionContainer}>
                <Grid item xs={12} sm={12} md={12} className={classes.contentContainer}>
                    {
                        props.isRegistrationSuccess ? <h1 className={classes.loginHeaderStyles}>
                            Congratulations! Your registration is complete.
                        </h1> : <h1 className={classes.loginHeaderStyles}>
                            You are already registered
                        </h1>
                    }
                </Grid>
                {
                    props.isRegistrationSuccess ?
                        <Grid container spacing={6} className={classes.gridSeparation}>
                            <Grid item xs={12} md={12} sm={12}>
                                <p className={classes.loginBodyStyles}>
                                    Log in to see your account details.
                                </p>
                            </Grid>
                            <Grid item xs={12} md={4} sm={12} className={classes.buttonWrapper}>
                                <Button
                                    name="BackToLoginButton"
                                    id="BackToLoginButton"
                                    disabled={props.isAwaitingApiCall}
                                    saving={props.isAwaitingApiCall}
                                    onClick={() => props.postCall()}>My account</Button>
                            </Grid>
                            {
                                productList.length > 0 &&
                                <Grid spacing={6} item xs={12} md={7} sm={8} className={classes.userNotesSeparation}>
                                        <span className={classes.userNotesStyles}>
                                            <p>Remember, your User ID signs you in for:
                                                <ul id="application-list">
                                                    {
                                                        productList.map(item=> <li>{item}</li> )
                                                    }
                                                </ul>
                                            </p>
                                        </span>
                                </Grid>
                            }
                        </Grid> 
                        :
                        <Grid container spacing={6} md={8} className={classes.gridSeparation}>
                            <Grid item xs={12}>
                                <p className={classes.loginBodyStyles}>
                                    Please use your existing username and password to log in. If you have forgotten, you can recover them on the sign in page
                                </p>
                            </Grid>
                            <Grid item xs={12} className={classes.loginButtonContainer}>
                                <Button
                                    name="BackToLoginButton"
                                    id="BackToLoginButton"
                                    onClick={e => redirectToLogin(props.formValues.appId)}>Back to sign In</Button> 
                            </Grid>
                        </Grid>
                }
            </Grid>
            {
                props.isRegistrationSuccess && props.formValues.appId === JsonConfig.app_id.VITALITY &&
                    <Grid item spacing={0} xs={12} sm={6} md={4}>
                        <QRCodeComponent classes={classes}/>
                    </Grid>
            }
        </Grid>
    );
};

export default RegisteredPage;