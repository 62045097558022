import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Button } from '@manulife/mux';
import JsonConfig from '../../utils/jsonConfig.json';
import { getQueryParams } from '../../utils';
import Utility from '../../utils/utility';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    errorHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "50px"
    },
    buttonPadding: {
        paddingTop: "25px",
        paddingBottom: "120px"
    },
    buttonContainer: {
        "& button": {
            fontWeight: "500",
            fontFamily: font,
            fontSize: "17px",
            lineHeight: "22px",
            fontStyle: "normal",
            textAlign: "center",
            color: "#FFFFFF",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    alreadyRegisteredContainer: {
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#282B3E",
        marginTop: "40px",
        marginBottom: "10px"
    },
    signInLink: {
        fontFamily: font,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#282B3E",
    }
}));

let { appId, jwt } = getQueryParams();

const MfaErrorPage = props => {

    const { mfaValidationMaxAttemptState, modalHook, setSessionTimeoutEnable, setPage, setSystemErrorCount, startOverResetForm } = props;

    const classes = useStyles();
    const [mfaValidationMaxAttempt, setMfaValidationMaxAttempt] = mfaValidationMaxAttemptState;
    const setModalOpen = modalHook[1];

    useEffect(() => {
        setSessionTimeoutEnable(!!appId) // in case of appId is null then don't display session expire page.
        Utility.scrollToTop()
    }, [])


    const redirectToHome = () => {
        let redirectUrl = JsonConfig.static_redirect_url[appId] || JsonConfig.static_redirect_url.default;
        Utility.redirectUrl(redirectUrl)
    };

    const gotofirstpage = () => {
        if ((appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY || appId === JsonConfig.app_id.PDCO) && jwt) {
            window.location.reload();
        } else {
            setPage(0)
            setSystemErrorCount(0)
            setModalOpen(false)
            setMfaValidationMaxAttempt(false)
            startOverResetForm();
        }
        return
    }

    const domainName = JsonConfig.domain_name[appId] || JsonConfig.domain_name.default;
    const isVitality = appId === JsonConfig.app_id.VITALITY;
    return (
        <div className={classes.root}>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                <span className={classes.errorHeaderStyles}>We're sorry.</span>
            </Grid>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                {
                    appId ? (mfaValidationMaxAttempt ? <span className={classes.subheaderStyles}>The number of validation attempts has been exceeded.</span> : <span className={classes.subheaderStyles}>Looks like we're having some trouble generating your code. Please try again later.</span>)
                        : <span className={classes.subheaderStyles}>Looks like we're having some trouble to complete your registration, Please try registering again.</span>

                }
            </Grid>
            <Grid container xs={12} className={classes.buttonPadding}>
                <Grid item md={8} xs={12} className={classes.buttonContainer}>
                    {
                        (mfaValidationMaxAttempt || appId === JsonConfig.app_id.PDCO)  ?
                            <Button
                                customStyle={{
                                    buttonStyle: {
                                        minWidth: "250px",
                                        width: "250px",
                                        height: "55px"
                                    }
                                }}
                                name="startOver"
                                id="startOver"
                                onClick={e => gotofirstpage()}
                            >Start over</Button>
                            : <Button
                                customStyle={{
                                    buttonStyle: {
                                        width: isVitality ? "298px" : "278px",
                                        height: "55px"
                                    }
                                }}
                                name="ReturnToJHButton"
                                id="ReturnToJHButton"
                                onClick={e => redirectToHome()}
                            >Return to {domainName}</Button>
                    }

                </Grid>
                {appId === JsonConfig.app_id.PDCO && (
                    <Grid container xs={12}>
                        <Grid item md={8} xs={12} className={classes.alreadyRegisteredContainer}>
                            Already Registered? <a className={classes.signInLink} href={process.env.REACT_APP_LOGIN_URL_PDCO}>Sign in</a>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default MfaErrorPage;