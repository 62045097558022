import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { classList, getQueryParams } from '../../utils';
import { ReactComponent as CheckIcon } from './images/check.svg';
import JsonConfig from '../../utils/jsonConfig.json';
import StepsJson from '../../utils/steps.json';
import Images from '../../utils/Images';

const font = "Manulife JH Sans";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        padding: "15px 0",
        marginBottom: "30px",
        [theme.breakpoints.up("md")]: {
            padding: 0,
            margin: 0
        },
        [theme.breakpoints.only("xs")]: {
            display: "none"
        }
    },
    stepList: {
        display: "flex",
        width: "100%",
        listStyle: "none",
        margin: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
            display: "block"
        },
        "& li": {
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            "&:last-child": {
                marginRight: 0,
            },
            "&, .open &": {
                [theme.breakpoints.up("md")]: {
                    paddingBottom: "25px",
                    margin: 0,
                }

            }
        },
        ".open &": {
            display: "block",
            "& li": {
                margin: "20px 0",
            },
        },
    },
    stepLabel: {
        display: "none",
        color: '#5e6073',
        fontFamily: font,
        fontSize: "16px",
        fontWeight:'600',
        ".active &, .open &": {
            display: "block",
            paddingLeft: "10px",
        },
        ".active &, .complete &": {
            color: '#282B3E',
        },
        [theme.breakpoints.up("md")]: {
            display: "block",
            padding: '6px 0px 10px 10px',
        }
    },
    icon: {
        display: "inline-block",
        width: "15px",
        height: "15px",
        lineHeight: "15px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "500",
        backgroundColor: "#FAFAFA",
        border: "2px solid #EDEDED",
        borderRadius: "50%",
        boxSizing: "border-box",
        "& span": {
            display: "none",
        },
        "& .check": {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            width: "30px",
            height: "30px",
            lineHeight: "26px", // height - borderWidth * 2
            "& span": {
                display: "inline",
            },
        },
        ".open &": {
            width: "30px",
            height: "30px",
            lineHeight: "26px", // height - borderWidth * 2
            "& span": {
                display: "inline",
            },
        },
    },
    iconActive: {
        width: "30px",
        height: "30px",
        lineHeight: "30px",
        border: "none",
        backgroundColor: "#00009A",
        "& span": {
            display: "inline",
            fontSize: "16px",
            color: "#FFFFFF",
        },
        ".open &": {
            lineHeight: "30px",
        },
    },
    iconComplete: {
        backgroundColor: "#282B3E",
        border: "none",
        "& span": {
            display: "none", // override desktop
        },
        "& .check": {
            display: "block",
        },
        ".open & span": {
            display: "none",
        },
    },
    collapseButton: {
        position: "absolute",
        right: 0,
        bottom: "50%",
        transform: "translateY(50%)",
        border: "none",
        backgroundColor: "transparent",
        paddingBottom: "3px", // account for rotation offset
        ".open &": {
            bottom: "15px",
            paddingTop: "3px", // account for rotation offset
            paddingBottom: 0, // account for rotation offset
        },
        "&:before": {
            content: "''",
            display: "inline-block",
            border: "solid #282B3E",
            borderWidth: "0 3px 3px 0",
            padding: "3px",
            transform: "rotate(45deg)",
            transition: "transform 0.5s",
            ".open &": {
                transform: "rotate(-135deg)",
            }
        },
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    horizontalStepperContainer: {
        display: "none",
        [theme.breakpoints.only("xs")]: {
            display: "inherit"
        },
        marginBottom: "40px",
    },
    horizontalStepper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "8px",
    },
    stepInfoContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },

    circle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        fontFamily: font,
        borderWidth: "2px",        
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "28px",

        color: "#5E6073",
        backgroundColor: "#FAFAFA",
        borderColor: "#EDEDED",  

        ".active &": {
            backgroundColor: "#0000C1",
            borderColor: "#0000C1",
            color: "#FFFFFF",
        },     
        ".completed &": {
            backgroundColor: "#282B3E",
            borderColor: "#282B3E",
            color: "#282B3E",
        },  
    },    
    
    line: {
        width: "38%",
        height: "2px",
        backgroundColor: "#EDEDED",
    },
    active: {
        backgroundColor: "#0000C1",
    }, 
    stepperText: {
        fontFamily: font,
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "28px",
        color: "#8E90A2"
    },
    activeStepperText: {
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "28px",
        color: "#202336"
    }
}));

let { appId, jwt } = getQueryParams();

function isInvitationFlow(){
    return (appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY || appId === JsonConfig.app_id.PDCO) && jwt;
}

function getSteps(isAuthenticationFlow){
    let isInvitation = isInvitationFlow();
    
    if(isInvitation && isAuthenticationFlow){
        return StepsJson.invitationAuthRegistrationSteps;
    }
    if(isInvitation){
        return StepsJson.invitationNewRegistrationSteps;
    }
    if(isAuthenticationFlow){        
        return StepsJson.authRegistrationSteps;
    }
    return StepsJson.newRegistrationSteps;;
}

function isActive(item, currentStep){
    
    let isInvitation = isInvitationFlow();

    if(!isInvitation){
        let isLastStep = currentStep === 4;

        return isLastStep ? currentStep-1 === item.step : currentStep === item.step;
    }

    if(isInvitation){
        let isLastStep = currentStep === 3;

        return isLastStep ? currentStep-1 === item.step : currentStep === item.step;
    }
}

function isCompleted(item, currentStep){
    
    let isInvitation = isInvitationFlow();

    if(!isInvitation){
        let isLastStep = currentStep === 4;

        return isLastStep ? currentStep-1 > item.step : currentStep > item.step;
    }

    if(isInvitation){
        let isLastStep = currentStep === 3;

        return isLastStep ? currentStep-1 > item.step : currentStep > item.step;
    }
}

const StepIcon = ({ stepText, isActive, isComplete }) => {
    const classes = useStyles();
    const className = classList({
        [classes.icon]: true,
        [classes.iconActive]: isActive,
        [classes.iconComplete]: isComplete,
    });

    return (
        <i className={className}>
            <span>{stepText}</span>
            <CheckIcon className="check" />
        </i>
    );
}

const StepItem = ({ item, currentStep }) => {
    const classes = useStyles();
    const isActiveFlag = isActive(item, currentStep)
    const isCompleteFlag = isCompleted(item, currentStep)

    return (
        <li className={isActiveFlag ? "active" : (isCompleteFlag ? "complete" : null)}>
            <StepIcon stepText={item.step} isActive={isActiveFlag} isComplete={isCompleteFlag} />
            <span className={classes.stepLabel}>{item.name}</span>
        </li>
    );
};

const Circle =({isActive, isCompleted, step}) => {
    const classes = useStyles()
    return  <div className={(isActive ? "active" : (isCompleted ? "completed" : ""))}>
                <div className={classes.circle}>
                    {isCompleted ? <img src={Images.CheckIC} alt="completed" stepText={step + 1} isActive={isActive} isComplete={isCompleted} /> :  step}
                </div>
            </div>
}

const Line =({isActive, length}) => {
    const classes = useStyles()
    return  <span style={length === 2 ? {width: "82%"} : {width: "38%"}} className={`${classes.line} ${isActive ? classes.active : ""}`} />
}

const HorizontalStepper = ({currentStep, isAuthenticationFlow}) =>{
    const classes = useStyles()

    let steps = getSteps(isAuthenticationFlow);

    return <div className={classes.horizontalStepperContainer}>
                <div className={classes.horizontalStepper}>
                    {
                        steps.map((item, index)=> {
                            return <React.Fragment>
                                    {(index !== 0) && <Line isActive={isActive(item, currentStep)} length={steps.length} />}
                                    <Circle isActive={isActive(item, currentStep)} isCompleted={isCompleted(item, currentStep)} step={item.step}/>
                                </React.Fragment>                     
                        })
                    }
                </div>
                <div className={classes.stepInfoContainer}>
                    {
                        steps.map((item, index)=> {
                            return <React.Fragment>                                    
                                    {isActive(item, currentStep) && <span className={classes.activeStepperText}>{item.name}</span>}
                                    {item.step === currentStep+1 && <span className={classes.stepperText}>{"Next: "+item.name}</span>}
                                </React.Fragment>                     
                        })
                    }
                </div>
            </div>
    
}

const VerticleStepper = ({isAuthenticationFlow, currentStep, isOpen, setIsOpen})=> {
    const classes = useStyles();
    const className = classList({
        [classes.root]: true,
        "open": isOpen,
    });
    let steps = getSteps(isAuthenticationFlow);

    return (
            <div className={className}>
                <ul id="step-list" className={classes.stepList}>
                    {
                        steps.map((item)=> <StepItem item={item} currentStep={currentStep} />)
                    }
                </ul>
            {/* {
                (appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY) && jwt ?
                <ul id="step-list" className={classes.stepList}>
                    <StepItem step={0} text={isAuthenticationFlow ? "Authentication" : "Create your profile"} currentStep={stepTemp-1} />
                    <StepItem step={1} text="Verify" currentStep={stepTemp-1} />
                </ul>
                :
                <ul id="step-list" className={classes.stepList}>
                    <StepItem step={0} text="Your information" currentStep={stepTemp} />
                    <StepItem step={1} text={isAuthenticationFlow ? "Authentication" : "Create credentials"} currentStep={stepTemp} />
                    <StepItem step={2} text="Verify" currentStep={stepTemp} />
                </ul>
            } */}
            <button
                className={classes.collapseButton}
                type="button"
                aria-controls="step-list"
                aria-expanded={isOpen}
                onClick={() => setIsOpen(!isOpen)}>
                    <Typography variant="srOnly">{isOpen ? "collapse steps" : "open steps"}</Typography>
            </button>
        </div>
    )
}

const RegistrationSteps = ({ step, isAuthenticationFlow }) => {
    const [isOpen, setIsOpen] = useState(false);
    
    let stepTemp = step === 4 ? 1 : step; // Added for optional step (Authentication & Create Credentials)
    stepTemp = isInvitationFlow() ? stepTemp - 1 : stepTemp; // invitation has one less step
    return <React.Fragment>
            <HorizontalStepper currentStep={stepTemp+1} isAuthenticationFlow={isAuthenticationFlow}/>
            <VerticleStepper currentStep={stepTemp+1} isAuthenticationFlow={isAuthenticationFlow} isOpen={isOpen} setIsOpen={setIsOpen}/>
        </React.Fragment>
}

export default RegistrationSteps