import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import JsonConfig from '../../utils/jsonConfig.json';
import VideoGuideModal from '../../Modals/VideoGuideModal';
import MyNotification from '../MyNotification/MyNotification';
import { OrangeVideoIc } from '../../utils/Images';
import { getQueryParams } from '../../utils';
import { Tooltip } from '@manulife/mux';
import { Info1 } from '@manulife/mux-cds-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  headerText: {
    display: "inline",
    margin: "0 auto",
    fontWeight: "600",
    fontFamily: "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif",
    fontSize: "48px",
    color: "#202336",
    lineHeight: "60px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "36px",
      lineHeight: "44px"
    },
  },
  subText: {
    fontWeight: "400",
    fontFamily: "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif",
    fontSize: "24px",
    color: "#202336",
    lineHeight: "32px",
    whiteSpace: "pre-line",

    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      lineHeight: "32px"
    },
  },
  gridSeparation: {
    paddingBottom: "32px"
  },  
  errorWrapper: {
      paddingBottom: "40px"
  },
  errorIcon: {
      float: "left",
      height: "17px",
      width: "17px",
      marginTop: "2px",
      color: "black !important",
      content: " ",
      background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',

  },
  errorLabelStyle: {
      color: "#A00E18 !important",
      fontWeight: "300px",
      marginLeft: "5px"
  },
  
  watchVideoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  videoIconTextContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer"
  },
  videoNeedHelp: {
    fontFamily: "\"Manulife JH Sans\"",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    marginRight: "10px"
  },
  videoIcon: {
      width: "24px",
      height: "24px",
      marginRight: "10px"
  },
  videoWatchText1: {
    fontFamily: "Manulife JH Sans",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    marginRight: "10px"
},

videoWatchText2: {
  fontFamily: "Manulife JH Sans",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "24px",
  marginLeft: "8px",
  color: "#282B3E"
}

}));

let { jwt } = getQueryParams();
const tooltipTextElectronicDelivery = "Not all communications, notices and disclosures, are currently available to be delivered electronically, so you may still receive some paper mail until enhancements are made.";
const PageHeader = ({ 
                  
  appId, text, subText = null, errorType = null, maintenanceDetails = null, step, page }) => {
  const classes = useStyles();
  let [isVideoModalVisible, setVideoModalVisible] = useState(false);
  let [hovered, setHover] = useState(false);
  
  return (
    <Grid container>
      {
          errorType === 'Maintenance' &&
          <Grid item md={9} xs={12}>
              <div className={classes.errorWrapper}>
                  <div className={classes.errorIcon} />
                      <span className={classes.errorLabelStyle}>
                          {
                            maintenanceDetails?.requestid ? maintenanceDetails.requestid : "The system is currently undergoing scheduled maintenance. If you are experiencing registration issues, please try again later. Thank you for your patience."
                          }
                      </span>
              </div>
          </Grid>
      }
      <Grid item xs={12} className={classes.gridSeparation}>
        <h1 className={classes.headerText}>{text}
          {
          page===6 && 
          <sup>
            <Tooltip title={tooltipTextElectronicDelivery} bubbleWidth={290}
                customStyle={{
                    rootStyle: {
                        paddingLeft: "5px",
                        marginTop: "-5px"
                    }
                }}><Info1 color="#282B3E" />
            </Tooltip>
          </sup>           
        }
        </h1>
      </Grid>      
      <MyNotification />
      {subText &&
        <Grid item xs={12} style={{paddingBottom: "40px"}}>
          <span className={classes.subText}>{subText}</span> 
        </Grid>
      }
      {
        appId === JsonConfig.app_id.DTC && step === 0 &&
        <Grid item xs={12} style={{paddingBottom: "40px"}}>
            <div className={classes.watchVideoContainer}>
              <span className={classes.videoNeedHelp}>Need help registering?</span>
              <span 
                    id="video-guide-button"
                    onMouseOver={()=> setHover(true)}
                    onMouseOut={()=> setHover(false)}
                    onClick={()=> setVideoModalVisible(true)} 
                    className={classes.videoIconTextContainer}>

                <img className={classes.videoIcon} alt="watch video" 
                    src={require(hovered ? "../../images/video_ic_hover.png" : "../../images/video_ic.png")}/>
                <span className={classes.videoWatchText1}>Watch the video</span>
              </span>
            </div>
          </Grid>
      }
      
      {
        appId === JsonConfig.app_id.VITALITY  && (step === 0 || step === 1) &&
        <Grid item xs={12} style={{paddingBottom: "40px"}}>
            <div className={classes.watchVideoContainer}>
              <span  
                    id="video-guide-button"
                    onMouseOver={()=> setHover(true)}
                    onMouseOut={()=> setHover(false)}
                    onClick={()=> setVideoModalVisible(true)} 
                    className={classes.videoIconTextContainer}>

                <img src={OrangeVideoIc} className={classes.videoIcon} alt="watch video"  style={{ width: "20px", height: "20px", background: "none"}}/>
                <span className={classes.videoWatchText2}>Watch the video to learn more about Vitality</span>
              </span>
            </div>
          </Grid>
      }
      <VideoGuideModal isVideoModalVisible={isVideoModalVisible} setVideoModalVisible={setVideoModalVisible}/>
    </Grid>
  );
};

export default PageHeader;
