import React from 'react'
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from "@material-ui/core/styles";
import { Progress } from '@manulife/mux';
import { Grid } from '@material-ui/core';
import { hasNumbers, hasUpper, hasLower, hasSpecial, hasEight, sequentialRepeat } from '../../utils/ValidatePassword';
import InputError from '../InputError/InputError';


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
    },
    strengthContainer: {
        position: "relative",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        marginBottom: "10px",
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
    },
    inlineDisplay2: {
        display: "inline-block"
    },
    strengthText: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "15px",
        lineHeight: "32px",
        color: "#0000C1",
        cursor: "pointer"
    },
    strengthCheckingText: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "300",
        marginTop: "10px",
        fontSize: "15px",
        lineHeight: "32px",
        color: "#5E6073"
    },
    errorPasswordLabel: {
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        margin: "0.5em 0px 10px"
    },
    strengthCheckPassed: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "15px",
        lineHeight: "32px",
        color: "#06874E"
    },
    listRoot: {
        display: 'block',
        marginTop: "20px",
        '& ul': {
            listStyle: 'none',
            padding: 0,
            margin: 0,
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'flex-start',
            whiteSpace: 'nowrap',
            '& ul': {
                flexGrow: 0,
                flexShrink: 1,
                '&:last-child li': {
                    paddingLeft: '2rem'
                }
            }
        }
    },
    iconStylesGrey: {
        fontSize: '14px',
        color: '#5E6073',
        paddingRight: '2px',
        marginRight: '5px'
    },
    iconStylesGreen: {
        fontSize: '14px',
        color: '#00A758',
        paddingRight: '2px',
        marginRight: '5px'
    },
    iconStylesRed: {
        fontSize: '14px',
        color: '#A00E18',
        paddingRight: '2px',
        marginRight: '5px'
    },
    passwordValidityTextStylesGrey: {
        fontFamily: font,
        fontSize: "14px",
        color: '#5E6073'
    },
    passwordValidityTextStylesGreen: {
        fontFamily: font,
        fontSize: "14px",
        color: '#00A758'
    },
    passwordValidityTextStylesRed: {
        fontFamily: font,
        fontSize: "14px",
        color: '#A00E18'
    }
}));

const PasswordStrengthItem = ({ password, text, assertion, classes, isContextWordsPassed }) => {
    const isValid = assertion(password);
    return (
        <li>
            {(isContextWordsPassed === undefined && isValid) || (isContextWordsPassed)
                ? <CheckIcon className={classes.iconStylesGrey} />
                : <ClearIcon className={classes.iconStylesGrey} />}
            <span className={classes.passwordValidityTextStylesGrey}>
                {text}
            </span>
        </li>
    );
}


const PasswordStrengthValidator = props => {
    const classes = useStyles();

    const { password, onPasswordCheck, errorState, isCheckingPassStrength, passwordValidated, isContextWordsPassed, isSequentialPassed, isValidPassword } = props;

    return (
        <div className={classes.root}>
            <Grid item md={9} xs={12} className={classes.strengthContainer}>
                {
                    !isCheckingPassStrength && passwordValidated && isContextWordsPassed && isSequentialPassed && isValidPassword &&
                    <span id="password-strength-check-passed" onClick={onPasswordCheck} className={classes.strengthCheckPassed}>Password strength check passed!</span>
                }
                {
                    isCheckingPassStrength ?
                        <span onClick={onPasswordCheck} className={classes.strengthCheckingText}>Password strength - Checking...</span>
                        :
                        !(passwordValidated && isContextWordsPassed && isSequentialPassed && isValidPassword) && (errorState?.password?.isError && errorState?.password?.errMsg) ?
                            <InputError errorState={errorState} inputId="password" showIcon={true}/>
                            : !(passwordValidated && isContextWordsPassed && isSequentialPassed && isValidPassword) &&
                            <span id="check-password-strength" onClick={onPasswordCheck} className={classes.strengthText}>Check password strength</span>
                }
                {
                    isCheckingPassStrength &&
                    <div className={classes.inlineDisplay}>
                        <div className={classes.inlineDisplay2}>
                            <Progress
                                size={30}
                                isFloating={false}
                                color="#0000C1"
                                customStyle={{
                                    spinnerStyle: {
                                        position: "relative",
                                        height: "30px",
                                        width: "30px",
                                        marginTop: "10px"
                                    }
                                }} />
                        </div>
                    </div>
                }
            </Grid>
            <div className={classes.listRoot}>
                <ul>
                    <PasswordStrengthItem
                        password={password}
                        text="Eight characters minimum"
                        assertion={(password) => !hasEight(password)}
                        classes={classes}
                    />
                    <PasswordStrengthItem
                        password={password}
                        text="One number"
                        assertion={(password) => !hasNumbers(password)}
                        classes={classes}
                    />
                    <PasswordStrengthItem
                        password={password}
                        text="One special character"
                        assertion={(password) => !hasSpecial(password)}
                        classes={classes}
                    />
                    <PasswordStrengthItem
                        password={password}
                        text="One lowercase letter"
                        assertion={(password) => !hasLower(password)}
                        classes={classes}
                    />
                </ul>
                <ul>
                    <PasswordStrengthItem
                        password={password}
                        text="One uppercase letter"
                        assertion={(password) => !hasUpper(password)}
                        classes={classes}
                    />
                    <PasswordStrengthItem
                        password={password}
                        text="No common, personal, or company related words"
                        classes={classes}
                        assertion={(password) => isContextWordsPassed}
                    />

                    <PasswordStrengthItem
                        password={password}
                        text="No sequential numbers or letters"
                        assertion={(password) => isSequentialPassed}
                        classes={classes}
                    />

                    <PasswordStrengthItem
                        password={password}
                        text="Other password requirements"
                        assertion={(password) => isValidPassword}
                        classes={classes}
                    />
                </ul>
            </div>
        </div>
    );
}

export default PasswordStrengthValidator