import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Container } from '@material-ui/core'
import { ReactComponent as FooterLogo } from '../../images/footer-logo.svg';
import {getQueryParams} from "../../utils";
import JsonConfig from '../../utils/jsonConfig.json';
import FooterContent from '../FooterContent';


const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0, // for the sticky footer
        width: "100%",
        background: "#fff",
        marginTop: "100px",
    },
    footer: {
        width: "100%",
        background: "#2F3242",
    },
    container: {
        paddingTop: "60px",
        paddingBottom: "60px",
        flexFlow: "column-reverse",
        [theme.breakpoints.up("sm")]: {
            flexFlow: "row",
        }
    },
    logoStyles: {
        textAlign: "center",
        "& svg": {
            maxWidth: "100%"
        },
        [theme.breakpoints.up("sm")]: {
            paddingRight: "50px",
            textAlign: "left"
        }
    },
    bodyStyles: {
        padding: "0 30px 30px",
        [theme.breakpoints.up("sm")]: {
            padding: 0
        }
    },
    labelStyles: {
        margin: 0,
        fontWeight: "normal",
        fontFamily: "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif",
        fontSize: "15px",
        display: "block",
        color: "#FFFFFF",
        lineHeight: "24px",
        opacity: "0.8"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    linkStyles: {
        color: "#FFFFFF",
        textDecoration: "none",
        fontFamily: "Manulife JH Sans",
        fontSize: "14px",
        whiteSpace: "nowrap",
        '&:hover': {
            textDecoration: "underline"
        }
    },
    linkList: {
        display: "flex",
        listStyle: "none",
        padding: 0,
        margin: 0,
        opacity: "0.8",
        "& li": {
            '&:after': {
                content: '"|"',
                display: 'inline-block',
                color: "#FFFFFF",
                padding: '0 1ch',
            },
            '&:last-child:after': {
                content: "none"
            }
        }
    },
    newLine: {
        display: "block"
    }
}));

const Footer = (props) => {
    const classes = useStyles();

    let { appId } = getQueryParams();

    
    let supportNumber = JsonConfig.call_center_number[appId] ? JsonConfig.call_center_number[appId] : JsonConfig.call_center_number.default;
    let startTime = JsonConfig.time[appId] ? JsonConfig.time[appId].start_time : JsonConfig.time.default.start_time;
    let endTime = JsonConfig.time[appId] ? JsonConfig.time[appId].end_time : JsonConfig.time.default.end_time;;

    let validatePhoneNumberRegex = /^[0-9\-/?]*$/;

    return (
        <Grid data-testid="footer-root" className={classes.root}>
            {
                appId === JsonConfig.app_id.VITALITY &&
                    <FooterContent/>
            }
            <Grid className={classes.footer}>
                <Container>
                    {
                        appId !== JsonConfig.app_id.DTC && process.env.REACT_APP_ENABLE_CHATBOT === "enabled" && 
                            <div className="jh-webchat" id="chatBotDiv" />
                    }
                    <Grid container spacing={0} className={classes.container}>
                        <Grid id="footer-logo" item md={3} xs={12} className={classes.logoStyles}>
                            <FooterLogo/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container className={classes.bodyStyles}>
                                <Grid item xs={12} className={classes.gridSeparation}>
                                    <ul id="footer-links-list" className={classes.linkList}>
                                        <li>
                                            <a id="accessibility-footer-link" className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/accessibility.html" target="_blank">Accessibility</a>
                                        </li>
                                        <li>
                                            <a id="privacysecurity-footer-link" className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/privacysecurity.html" target="_blank">Privacy &amp; Security</a>
                                        </li>
                                        <li>
                                            <a id="legal-footer-link" className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/legal.html" target="_blank">Legal</a>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        appId &&
                                        <p id="footer-contact-content" className={classes.labelStyles}>
                                            If you need assistance registering or already have an online account with John Hancock, please call&nbsp;
                                       {
                                            appId === JsonConfig.app_id.PDCO ? supportNumber :<a href={`tel:${validatePhoneNumberRegex.test(supportNumber) ? supportNumber : ""}`} className={classes.linkStyles}>{supportNumber}</a>
                                               
                                        }  
                                       , Monday - Friday {startTime} {endTime}.
                                        </p>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} xs={12} /> {/* empty right gutter to balance layout */}
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default Footer;
