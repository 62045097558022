import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@manulife/mux';
import { getCustomization } from "../../utils";
import Utility from "../../utils/utility";

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    successHeaderStyles: {
        fontWeight: "600",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "60px",
        color: "#202336",
        margin: 0
    },
    successBodyStyles: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0,

        "& ul": {
            "& li": {
                marginBottom: "20px",
                "& span": {
                    "& strong": {
                        fontWeight: "600"
                    }
                }
            }
        }
    },
    buttonContainer: {
        display: "flex",
        marginTop: "20px",

        "& button": {
            width: "140px",
            fontWeight: "700",
            fontFamily: font,
            fontSize: "18px",
            lineHeight: "31.5px",
            textAlign: "center",
            color: "#FFFFFF",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }

        }
    }
}));

const PDCORegistrationSuccessPage = props => {
    const { productList } = props;

    const { successBody, successCTA } = getCustomization(props.formValues.appId);
    const [mProductList, setMProductList] = useState([]);

    useEffect(() => {
        props.setSessionTimeoutEnable(true);
        Utility.scrollToTop();

    }, []);

    useEffect(() => {

        if (productList && productList.length > 0) {
            let tempList = [...productList];
            tempList.sort((a, b) => a.localeCompare(b))
            setMProductList(tempList);
        } else {
            setMProductList([]);
        }
    }, [productList])

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container xs={8} className={classes.gridSeparation}>
                <h1 className={classes.successHeaderStyles}>
                    Registration complete
                </h1>
            </Grid>
            <Grid container spacing={4} md={12} className={classes.gridSeparation}>
                <Grid item xs={9}>
                    <p className={classes.successBodyStyles}>
                    You have successfully created your online profile. With your user name and password, you now have access to:
                    </p>
                </Grid>
                {
                    mProductList.length > 0 &&
                    <Grid item xs={12} md={12} className={classes.successBodyStyles}>
                        <ul>
                        {
                            mProductList.map((item) => {
                                return (<li>
                                {
                                    item === 'Life Customer Portal' ? <span><strong>JohnHancock.com/lifecustomer –</strong> save time by leveraging our digital tools and information that were designed with you in mind</span> :
                                    item === 'John Hancock Vitality' ? <span><strong>JohnHancockVitality.com –</strong> earn discounts and reward for the everyday things you do to stay healthy</span> :
                                    <span>{item}</span>
                                }
                                </li>)
                            })
                        }
                     </ul>
                    </Grid>
                }
                <Grid item xs={9}>
                    <p className={classes.successBodyStyles}>
                    Please click the “Continue” button to proceed to the next step (i.e., applying your signature).
                    </p>
                </Grid>
            </Grid>
            <Grid item xs={12} md={props.isAwaitingApiCall ? 6 : 3} className={classes.buttonContainer}>
                <Button
                    disabled={props.isAwaitingApiCall}
                    saving={props.isAwaitingApiCall}
                    onClick={() => props.postCall()}
                    name="ContinueButton"
                    id="ContinueButton">
                    {successCTA || 'Continue'}
                </Button>
            </Grid>
        </div>
    )
}

export default PDCORegistrationSuccessPage;