import React, { useState, useEffect, useContext } from 'react';
import {
    generateMfa,
    validateMfa,
    registerApi,
    postCall,
    authenticateUser
} from '../../api';
import { getQueryParams } from '../../utils';
import { validate } from './validate'
import {
    UserInfoPage,
    CredentialsPage,
    MfaPage,
    MfaVerificationPage,
    SuccessPage,
    ErrorPage,
    SessionExpiredPage,
    MaintenancePage,
    RegisteredPage,
    AuthenticationPage,
    UpdatePasswordPage,
    LTCInterceptPage,
    DTCRegisterErrorPage,
    MfaErrorPage,
    LinkExpiredPage,
    SuccessInvitePage,
    WelcomePage,
    SetPreferencesPage,
    PDCORegistrationSuccessPage
} from '../../pages';
import { RegistrationColumnLayout } from '../';
import LoginPage from '../../pages/LoginPage/LoginPage';
import deleteUser from '../../api/deleteUser';
import checkMaintenance from '../../api/checkMaintenance';
import getIpAddress from '../../api/getIpAddress';
import verifyUser from '../../api/verifyUser';
import multilifeRegister from '../../api/multilifeRegister';
import AccountUnlockPage from '../../pages/AccountUnlockPage';
import unlockAccount from '../../api/unlockAccount';
import AnnuitiesPage from '../../pages/AnnuitiesPage/AnnuitiesPage';
import { useIdleTimer } from 'react-idle-timer'
import { initJHChatBotBundle, loadChatBot } from '../../scripts/ChatBotScript';
import JsonConfig from '../../utils/jsonConfig.json';
import Loader from '../../components/Loader';
import { MyContext } from '../../Context/MyContext';
import useAdobeDataLayer from '../../hooks/useAdobeDataLayer';

let { appId, jwt } = getQueryParams();


const PageBody = props => {

    const context = useContext(MyContext);

    const [page, setPage] = useState(props.page);

    const [errorScenario, setErrorScenario] = useState(false);
    const [accountUnlock, setAccountUnlock] = useState(false);
    const [maintenanceCall, setMaintenanceCall] = useState(true);
    const [unlockToken, setUnlockToken] = useState('');
    const [isamPasswordError, setIsamPasswordError] = useState(false);
    const [productList, setProductList] = useState([]);
    const [redirecturl, setRedirectUrl] = useState('');
    const mfaValidationMaxAttemptState = useState(false);
    const [mfaValidationMaxAttempt] = mfaValidationMaxAttemptState;

    const authenticateErrorHook = useState(0);
    const [authenticateError] = authenticateErrorHook;

    const [loginHook, setLoginHook] = useState(false)
    const [sessionExpired, setSessionExpired] = useState(false);
    const [alreadyRegistered, setAlreadyRegistered] = useState(false)
    const [passwordUpdateRequired, setpasswordUpdateRequired] = useState(false)
    const modalHook = useState(false)
    const [isModalOpen, setModalOpen] = modalHook;
    const [isPaperlessTCModalOpen, setPaperlessTCModalOpen] = useState(false);
    const [dtcRegisterError, setDtcRegisterError] = useState(false);
    const [mfaAttemptMaxed, setMfaAttemptMaxed] = useState(false)
    const [appid, setAppid] = useState('')
    const [sessionTimeoutEnable, setSessionTimeoutEnable] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true);
    const [isReview, setReview] = useState(false);
    const [isPhoneNumberPresent, setPhoneNumberPresent] = useState(true);

    const [guid, setGuid] = useState('')
    const [errorType, setErrorType] = useState('')
    const [maintenanceDetails, setMaintenanceDetails] = useState(null)

    const systemErrorCountHook = useState(0);
    const [systemErrorCount, setSystemErrorCount] = systemErrorCountHook;

    const authErrorCountHook = useState(0);
    const [authErrorCount] = authErrorCountHook;

    const [linkExpired, setLinkExpired] = useState(false);
    const [displaySuccessInvitePage, setDisplaySuccessInvitePage] = useState(false);
    const [isAuthenticationFlow, setAuthenticationFlow] = useState(false); // this state is to seperate stepper for new and auth registration flow

    const [invitationResponseData, setInvitationResponseData] = useState(false);
    const [paperlessOrigin, setPaperlessOrigin] = useState("");


    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        day: null,
        month: null,
        year: null,
        emailAddress: '',
        lastSixDigitSSN: '',
        modalOpen: false,
        wolChecked: false,
        wolChecked2: false,
        goPaperless: false,
        notGoPaperless: false,
        userName: '',
        password: '',
        confirmPassword: '',
        mfaType: "SMS",
        phoneType: "",
        phoneNumber: '',
        userEnteredPhoneNumber: '',
        appId: appId,
        usernameAjaxCallfail: false,
        authUserId: '',
        authPassword: '',
        paperless: null
    })

    const [errorState, setErrorState] = useState({
        firstName: { id: 'firstname', errMsg: '', isError: false, warning: false },
        lastName: { id: 'lastName', errMsg: '', isError: false, warning: false },
        day: { id: 'day', errMsg: '', isError: false, warning: false },
        month: { id: 'month', errMsg: '', isError: false, warning: false },
        year: { id: 'year', errMsg: '', isError: false, warning: false },
        emailAddress: { id: 'emailAddress', errMsg: '', isError: false, warning: false },
        lastSixDigitSSN: { id: 'lastSixDigitSSN', errMsg: '', isError: false, warning: false },
        phoneType: { id: 'phoneType', errMsg: '', isError: false, warning: false },
        phoneNumber: { id: 'phoneNumber', errMsg: '', isError: false, warning: false },
        wolChecked: { id: 'wolChecked', errMsg: '', isError: false, warning: false },
        wolChecked2: { id: 'wolChecked2', errMsg: '', isError: false, warning: false },
        goPaperless: { id: 'goPaperless', errMsg: '', isError: false, warning: false },
        notGoPaperless: { id: 'notGoPaperless', errMsg: '', isError: false, warning: false },
        userName: { id: 'userName', errMsg: '', isError: false, warning: false },
        password: { id: 'password', errMsg: '', isError: false, warning: false },
        confirmPassword: { id: 'confirmPassword', errMsg: '', isError: false, warning: false },
        authUserId: { id: 'authUserId', errMsg: '', isError: false, warning: false },
        authPassword: { id: 'authPassword', errMsg: '', isError: false, warning: false },
        paperless: { id: 'paperless', errMsg: '', isError: false, warning: false }
    })

    const [isAwaitingApiCall, setIsAwaitingApiCall] = useState(false);
    const [clientIpAddress, setClientIpAddress] = useState("");
    const [annuities, setAnnuities] = useState({ isVisible: false, from: null })
    const [isRegistrationSuccess, setRegistrationSuccess] = useState(false);

    const adobeDataLayer = useAdobeDataLayer();

    useEffect(() => {
        console.log("appId==", appId);
        if (appId)
            getIpAddress().then((ipAddress => setClientIpAddress(ipAddress)));
    }, []);

    useEffect(() => {
        if (appId !== JsonConfig.app_id.DTC && process.env.REACT_APP_ENABLE_CHATBOT === "enabled" && window.isDOMContentLoaded) {
            let appName = JsonConfig.app_name[appId];
            let lob = JsonConfig.lob[appId];

            initJHChatBotBundle(() => {
                let data = {
                    lob: lob,
                    appId: appId,
                    channelData: appName,
                }
                loadChatBot(data);
            });
        }

    }, [window.isDOMContentLoaded, process.env.REACT_APP_ENABLE_CHATBOT]);


    function errorsFound(errorState) {
        let foundError = false
        for (let key in errorState) {
            if (errorState.hasOwnProperty(key)) {
                let obj = errorState[key];
                for (let prop in obj) {
                    if (prop === 'isError' && obj[prop] === true) {
                        foundError = true;
                        break;
                    }
                }
            }
            if (foundError) {
                break;
            }
        }
        return foundError;
    };


    const handleOnIdle = e => {
        if (sessionTimeoutEnable) {
            setSessionExpired(true)
            return
        }
    }

    // 15 min session timeout if in Idle mode
    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    })

    // handleSubmit
    const onClick = (pageNum, shouldCallApi) => {
        if (pageNum === 1) {

            const temperrors = validate(formValues, '', errorState, true, pageNum, null, false);
            setErrorState({ ...temperrors });
            if (errorsFound(errorState)) {
                return
            }

        } else if (pageNum === 2) {
            const temperrors = validate(formValues, '', errorState, true, pageNum, isPhoneNumberPresent, false);
            setErrorState({ ...temperrors });

            if (errorsFound(errorState)) {
                return
            } else {
                if (appId === JsonConfig.app_id.PDCO && jwt) {
                    setPaperlessOrigin("registration");
                    setPage(6);
                } else {
                    shouldCallApi && registerApi(formValues, setPage, setErrorScenario, systemErrorCount, setSystemErrorCount, guid, setLoginHook, setIsAwaitingApiCall, errorState, setErrorState, setAlreadyRegistered, setpasswordUpdateRequired, setDtcRegisterError, setErrorType, setGuid, setModalOpen, setReview, isPhoneNumberPresent, setAuthenticationFlow);
                }
                return
            }
        }else if (pageNum === 3) {
            generateMfa(formValues, setPage, systemErrorCount, setSystemErrorCount, guid, setErrorScenario, setIsAwaitingApiCall, setMfaAttemptMaxed, setErrorType, setModalOpen,null, paperlessOrigin)
            return
        } else if (pageNum === 4) {
            const temperrors = validate(formValues, '', errorState, true, pageNum, null, false);
            setErrorState({ ...temperrors });
            if (errorsFound(errorState)) {
                return
            } else {
                console.log("Calling authenticate user")
                authenticateUser(formValues, guid, setGuid, setIsAwaitingApiCall, setErrorScenario, authErrorCountHook, setFormValues, setErrorType, setpasswordUpdateRequired, setAccountUnlock, setAppid, setUnlockToken, setIsamPasswordError, setProductList, setAnnuities, setPage, systemErrorCountHook, setAuthenticationFlow, setPaperlessOrigin);

                return
            }
        } else if (pageNum === 6) {
            const temperrors = validate(formValues, '', errorState, true, pageNum, isPhoneNumberPresent, false);
            setErrorState({ ...temperrors });

            if (errorsFound(errorState)) {
                return
            } else {
                shouldCallApi && registerApi(formValues, setPage, setErrorScenario, systemErrorCount, setSystemErrorCount, guid, setLoginHook, setIsAwaitingApiCall, errorState, setErrorState, setAlreadyRegistered, setpasswordUpdateRequired, setDtcRegisterError, setErrorType, setGuid, setModalOpen, setReview, isPhoneNumberPresent, setAuthenticationFlow);
                return
            }
        } else if (pageNum === 7) {
            const temperrors = validate(formValues, '', errorState, true, pageNum, isPhoneNumberPresent, false);
            setErrorState({ ...temperrors });
            // load mfa page after authenticate paperless preferences
            if (errorsFound(errorState)) {
                return
            } else {
                setPage(2);
            }
            
            return
        }

        setPage(pageNum)
    }

    function startOverResetForm() {
        setFormValues({ ...formValues, phoneNumber: formValues.userEnteredPhoneNumber, userName: "", password: "", confirmPassword: "", authUserId: "", authPassword: "" });
    }

    // This function responsible to go back to previous page (where it was opened) from annuities page
    function backFromAnnuities(from) {
        if (from === "UserInfoPage") {
            setPage(0);
            setAnnuities({ isVisible: false, from: null })
        } else if (from === "AuthenticationPage") {
            setAuthenticationFlow(true);
            setPage(4);
            setAnnuities({ isVisible: false, from: null })
        }
    }


    useEffect(() => {
        if (appId) {
            async function extractTokenAndMaintenance() {
                if ((appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY || appId === JsonConfig.app_id.PDCO) && jwt) {
                    await multilifeRegister(formValues, setFormValues, setErrorScenario, systemErrorCount, setSystemErrorCount, setPage, setIsAwaitingApiCall, setErrorType, setGuid, setDtcRegisterError, setAlreadyRegistered, setAnnuities, setLinkExpired, setPhoneNumberPresent, setAuthenticationFlow, setInvitationResponseData)
                }
                await checkMaintenance(setMaintenanceCall, setErrorType, setMaintenanceDetails)
                setFullScreenLoader(false);
            }
            extractTokenAndMaintenance();
        } else {
            setFullScreenLoader(false);
        }
    }, []);

    //checks appid for PDCO to display Welcome Page
    useEffect(() => {
        if (appId === JsonConfig.app_id.PDCO && jwt) {
            setPage(5);
        }
    }, [appId])

    if (fullScreenLoader) {
        return <Loader />
    }

    if (sessionExpired) {
        return <SessionExpiredPage setSessionTimeoutEnable={setSessionTimeoutEnable} />
    }

    if (displaySuccessInvitePage) {
        return <SuccessInvitePage setSessionTimeoutEnable={setSessionTimeoutEnable} />
    }

    if (linkExpired) {
        return <LinkExpiredPage setSessionTimeoutEnable={setSessionTimeoutEnable} setErrorScenario={setErrorScenario} systemErrorCount={systemErrorCount} setSystemErrorCount={setSystemErrorCount} setLinkExpired={setLinkExpired} setDisplaySuccessInvitePage={setDisplaySuccessInvitePage} appId={appId}/>
    }

    if (errorType === 'Technical') {
        return <MaintenancePage errorType={errorType} setSessionTimeoutEnable={setSessionTimeoutEnable} />
    }

    if (errorScenario) {
        return <ErrorPage isReview={isReview} setSessionTimeoutEnable={setSessionTimeoutEnable} />;
    }

    if (accountUnlock) {
        return <AccountUnlockPage setSessionTimeoutEnable={setSessionTimeoutEnable} isAwaitingApiCall={isAwaitingApiCall} unlockAccount={() => unlockAccount(appid, setIsAwaitingApiCall, setErrorType, unlockToken)} />;
    }

    if (!appId || mfaAttemptMaxed || mfaValidationMaxAttempt) {
        return <MfaErrorPage setSessionTimeoutEnable={setSessionTimeoutEnable} setPage={setPage} mfaValidationMaxAttemptState={mfaValidationMaxAttemptState} setSystemErrorCount={setSystemErrorCount} modalHook={modalHook} startOverResetForm={startOverResetForm} />
    }

    if ((isRegistrationSuccess && (appId !== JsonConfig.app_id.PDCO)) || alreadyRegistered) {
        return <RegisteredPage setSessionTimeoutEnable={setSessionTimeoutEnable} formValues={formValues} isRegistrationSuccess={isRegistrationSuccess} isAwaitingApiCall={isAwaitingApiCall} productList={productList} postCall={() => postCall(formValues, formValues.appId, setLoginHook, setIsAwaitingApiCall, isAuthenticationFlow,redirecturl)} />
    }
    //loads new success page for pdco
    if ((isRegistrationSuccess && (appId === JsonConfig.app_id.PDCO))) {
        return <PDCORegistrationSuccessPage setSessionTimeoutEnable={setSessionTimeoutEnable} formValues={formValues} productList={productList} postCall={() => postCall(formValues, formValues.appId, setLoginHook, setIsAwaitingApiCall, isAuthenticationFlow,redirecturl)} />
    }

    if (passwordUpdateRequired) {
        return <UpdatePasswordPage setSessionTimeoutEnable={setSessionTimeoutEnable} />
    }

    if (loginHook) {
        return <LoginPage setSessionTimeoutEnable={setSessionTimeoutEnable} formValues={formValues} />
    }

    if (dtcRegisterError) {
        return <DTCRegisterErrorPage appId={appId} setSessionTimeoutEnable={setSessionTimeoutEnable} />;
    }

    if (annuities.isVisible) {
        return <AnnuitiesPage setSessionTimeoutEnable={setSessionTimeoutEnable} backFromAnnuities={backFromAnnuities} from={annuities.from} />;
    }


    switch (page) {

        case 0:
            return (
                <RegistrationColumnLayout
                    isAuthenticationFlow={isAuthenticationFlow}
                    appId={appId}
                    step={page}
                    text="Registration"
                    errorType={errorType}
                    maintenanceDetails={maintenanceDetails}
                    subText="Welcome to your one-stop location to access and manage your account. To get started, complete the simple registration below."
                >
                    <UserInfoPage onClick={onClick}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        errorState={errorState}
                        isModalOpen={isModalOpen}
                        setModalOpen={setModalOpen}
                        isPaperlessTCModalOpen={isPaperlessTCModalOpen}
                        setPaperlessTCModalOpen={setPaperlessTCModalOpen}
                        setErrorState={setErrorState}
                        systemErrorCount={systemErrorCount}
                        setSystemErrorCount={setSystemErrorCount}
                        errorsFound={errorsFound}
                        isAwaitingApiCall={isAwaitingApiCall}
                        setIsAwaitingApiCall={setIsAwaitingApiCall}
                        setSessionTimeoutEnable={setSessionTimeoutEnable}
                        appId={appId}
                        verifyUser={() => verifyUser(formValues, setErrorScenario, systemErrorCount, setSystemErrorCount, setPage, setIsAwaitingApiCall, setErrorType, modalHook, setGuid, setDtcRegisterError, setAlreadyRegistered, setAnnuities, setAuthenticationFlow, adobeDataLayer)}
                    />
                </RegistrationColumnLayout>
            );
        case 1:
            return (
                <RegistrationColumnLayout
                    isAuthenticationFlow={isAuthenticationFlow}
                    appId={appId}
                    step={page}
                    text="Registration"
                    subText="Next, create and confirm your password. Please follow the guidelines to safeguard your account from unauthorized access and use."
                >
                    <CredentialsPage onClick={onClick}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        errorState={errorState}
                        setErrorState={setErrorState}
                        isModalOpen={isModalOpen}
                        setModalOpen={setModalOpen}
                        isPaperlessTCModalOpen={isPaperlessTCModalOpen}
                        setPaperlessTCModalOpen={setPaperlessTCModalOpen}
                        systemErrorCount={systemErrorCount}
                        setSystemErrorCount={setSystemErrorCount}
                        errorsFound={errorsFound}
                        isAwaitingApiCall={isAwaitingApiCall}
                        setIsAwaitingApiCall={setIsAwaitingApiCall}
                        setSessionTimeoutEnable={setSessionTimeoutEnable}
                        appId={appId}
                        guid={guid}
                        isPhoneNumberPresent={isPhoneNumberPresent}
                        setPage={setPage}
                    />
                </RegistrationColumnLayout>
            );
        case 2:
            return (
                <RegistrationColumnLayout
                    isAuthenticationFlow={isAuthenticationFlow}
                    appId={appId}
                    step={page}
                    text="Registration"
                    disclosure={true}
                    subText="Great! The last step is to verify your identity. Because we’re committed to protecting the confidentiality of your personal information, we take serious measures to keep your account safe."
                >
                    <MfaPage
                        formValues={formValues}
                        setFormValues={setFormValues}
                        onClick={onClick}
                        isAwaitingApiCall={isAwaitingApiCall}
                        setSessionExpired={setSessionExpired}
                        systemErrorCount={systemErrorCount}
                        setSessionTimeoutEnable={setSessionTimeoutEnable}
                        deleteUser={() => deleteUser(guid, "MFA")}
                    />
                </RegistrationColumnLayout>
            );
        case 3:
            return (
                <RegistrationColumnLayout
                    isAuthenticationFlow={isAuthenticationFlow}
                    appId={appId}
                    step={page}
                    text="Registration"
                    subText="Please check the code from your phone. The code is valid for 10 minutes. If your code has expired, please click 'Get a new code' below to request a new Security Code. You will be logged out after too many failed attempts."
                >
                    <MfaVerificationPage onClick={onClick}
                        verify={(mfaCode, setMfaCode, setMfaValidationFailed) => validateMfa(formValues.phoneNumber, mfaCode, setMfaCode, systemErrorCountHook, guid, setErrorScenario, setIsAwaitingApiCall, mfaValidationMaxAttemptState, setErrorType, setProductList, clientIpAddress, setAlreadyRegistered, setRegistrationSuccess, setMfaValidationFailed, adobeDataLayer,setRedirectUrl)}
                        deleteUser={() => deleteUser(guid, "MFA")}
                        setSessionExpired={setSessionExpired}
                        isAwaitingApiCall={isAwaitingApiCall}
                        systemErrorCount={systemErrorCount}
                        setSessionTimeoutEnable={setSessionTimeoutEnable}
                        generateMfa={() => generateMfa(formValues, setPage, systemErrorCount, setSystemErrorCount, guid, setErrorScenario, setIsAwaitingApiCall, setMfaAttemptMaxed, setErrorType, setModalOpen, context, paperlessOrigin)}
                    />
                </RegistrationColumnLayout>
            );
        case 4:
            return (
                <RegistrationColumnLayout
                    isAuthenticationFlow={isAuthenticationFlow}
                    appId={appId}
                    step={1}
                    text="Registration"
                    subText={"We noticed you have existing accounts with us\n\nlog in to finish your registration."}
                >
                    <AuthenticationPage
                        systemErrorCount={systemErrorCount}
                        setSessionTimeoutEnable={setSessionTimeoutEnable}
                        onClick={onClick}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        authenticateError={authenticateError}
                        errorState={errorState}
                        setErrorState={setErrorState}
                        isModalOpen={isModalOpen}
                        setModalOpen={setModalOpen}
                        isPaperlessTCModalOpen={isPaperlessTCModalOpen}
                        setPaperlessTCModalOpen={setPaperlessTCModalOpen}
                        isAwaitingApiCall={isAwaitingApiCall}
                        authErrorCount={authErrorCount}
                        isamPasswordError={isamPasswordError}
                    />
                </RegistrationColumnLayout>
            );
        case 5:
            return (
                <WelcomePage
                    setPage={setPage}
                    invitationResponseData={invitationResponseData}
                    setAlreadyRegistered={setAlreadyRegistered}
                    setSessionTimeoutEnable={setSessionTimeoutEnable}
                    setAnnuities={setAnnuities}
                ></WelcomePage>
            );
        case 6:
            return (
                <RegistrationColumnLayout
                    isAuthenticationFlow={isAuthenticationFlow}
                    appId={appId}
                    step={1}
                    page={page}
                    text="Electronic delivery (recommended)"
                    subText="Electronic delivery is the fastest and most secure way to receive key policy correspondence. Check the “Yes, go paperless” box if you would like to select this communication method once your policy is in–force."
                >
                    <SetPreferencesPage
                        onClick={onClick}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        errorState={errorState}
                        setErrorState={setErrorState}
                        isAwaitingApiCall={isAwaitingApiCall}
                        paperlessOrigin={paperlessOrigin}
                        productList={productList}
                        isPaperlessTCModalOpen={isPaperlessTCModalOpen}
                        setPaperlessTCModalOpen={setPaperlessTCModalOpen}
                        setSessionTimeoutEnable={setSessionTimeoutEnable}
                    />
                </RegistrationColumnLayout>
            )
        default:
            return null;
    }
}

export default PageBody;
