import React from 'react';
import { Grid } from '@material-ui/core';
import RegistrationSteps from '../RegistrationSteps';
import PageHeader from '../PageHeader';
import Disclosures from "../Disclosures";

const RegistrationColumnLayout = ({
  appId,
  step,
  page,
  text,
  disclosure,
  subText = null,
  children,
  errorType,
  maintenanceDetails,
  isAuthenticationFlow
}) => {
  return (
    <Grid container>
      <Grid item xs={12} md={8} id="page-header">
        <PageHeader step={step} page={page} appId={appId} errorType={errorType} maintenanceDetails={maintenanceDetails} text={text} subText={subText} />
      </Grid>
      <Grid item xs={12} md={4} /> {/* empty right gutter to balance layout */}
      <Grid item xs={12} md={3}>
        <RegistrationSteps step={step} isAuthenticationFlow={isAuthenticationFlow}/>
      </Grid>
      <Grid item xs={12} md={6}>
        {children}
      </Grid>
      <Grid item xs={12} md={12}>
          {disclosure && <Disclosures />}
      </Grid>
    </Grid>
  );
};

export default RegistrationColumnLayout;
