
import moment from "moment";
import Strings from "../../constants/strings.json"
import { isValidPasword } from "../../utils/ValidatePassword";
import { getQueryParams } from "../../utils";
import JsonConfig from '../../utils/jsonConfig.json';
import PhoneInput, { isPossiblePhoneNumber} from 'react-phone-number-input';
const zxcvbn = require('zxcvbn');

//formvalues contain current content of submit form
//name is the name of the field to validate - this is blank it it is from onSubmit
//errorState is the object containing the current error state of all fields
//final indicates that this is the validation prior to moving to the next page
//page is the page that the validataion applies to.  All but username are page 1, username is page 2
let { appId, jwt } = getQueryParams();




const enterUserIdMsg = "Enter user ID";

export function validate(formValues, name, errorState, final, page, isPhoneNumberPresent, warning) {

    if (((name === 'firstName') || (final)) && page === 1) {
        if (!formValues.firstName && final) {
            errorState.firstName = { id: 'firstName', errMsg: Strings.validations.enter_first_name, isError: true, warning };
        } else if (!validateName('firstName', formValues.firstName)) { // check if this is a valid name
            let errorText_firstName = getFirstNameValidationError(formValues.firstName);
            errorState.firstName = { id: 'firstName', errMsg: errorText_firstName, isError: true, warning };
        } else {
            errorState.firstName = { id: 'firstName', errMsg: '', isError: false, warning: false };
        }
    }

    if (((name === 'lastName') || (final)) && page === 1) {
        if (!formValues.lastName && final) {
            errorState.lastName = { id: 'lastName', errMsg: Strings.validations.enter_last_name, isError: true, warning };
        } else if (!validateName('lastName', formValues.lastName)) { // check if this is a valid name
            let errorText_lastName = getLastNameValidationError(formValues.lastName);
            errorState.lastName = { id: 'lastName', errMsg: errorText_lastName, isError: true, warning };
        } else {
            errorState.lastName = { id: 'lastName', errMsg: '', isError: false, warning: false };
        }
    }

    if (((name === 'day') || (final)) && page === 1) {
        if ((!formValues.day && final)) {
            errorState.day = { id: 'day', errMsg: 'Select day', isError: true };
        } else {
            if(!validateDaysInaMonth(formValues.day, formValues.month, formValues.year)) {
                errorState.day = { id: 'day', errMsg: 'Select day', isError: true };
            } else {
                errorState.day = { id: 'day', errMsg: '', isError: false };
            }
        }
    }

    // if(!validateDaysInaMonth(formValues.day, formValues.month, formValues.year) && page === 1) {
    //     errorState.day = { id: 'day', errMsg: 'Select day', isError: true };
    // } else {
    //     errorState.day = { id: 'day', errMsg: '', isError: false };
    // }

    if (((name === 'month') || (final)) && page === 1) {
        if (!formValues.month && final) {
            errorState.month = { id: 'month', errMsg: 'Select month', isError: true };
        } else {
            errorState.month = { id: 'month', errMsg: '', isError: false };
        }
    }

    if (((name === 'year') || (final)) && page === 1) {
        if (!formValues.year && final) {
            errorState.year = { id: 'year', errMsg: 'Select year', isError: true };
        } else {
            errorState.year = { id: 'year', errMsg: '', isError: false };
        }
    }

    if (((name === 'emailAddress') || (final)) && page === 1) {
        if (!formValues.emailAddress) {
            errorState.emailAddress = { id: 'emailAddress', errMsg: 'Enter an email address', isError: true, warning };
        } else if (!validateEmail(formValues.emailAddress)) {
            errorState.emailAddress = { id: 'emailAddress', errMsg: 'Enter a valid email address', isError: true, warning };
        }
        else {
            errorState.emailAddress = { id: 'emailAddress', errMsg: '', isError: false, warning: false };

        }
    }

    if (((name === 'lastSixDigitSSN') || (final)) && page === 1) {

        const ssnWithoutSpaces = formValues.lastSixDigitSSN.replace(/\s/g, "")
        if (!formValues.lastSixDigitSSN || ssnWithoutSpaces.length < 6) {
            errorState.lastSixDigitSSN = { id: 'lastSixDigitSSN', errMsg: 'Enter last six digits of your social security number.', isError: true, warning };
        } else {
            errorState.lastSixDigitSSN = { id: 'lastSixDigitSSN', errMsg: '', isError: false, warning: false };
        }
        if(formValues.lastSixDigitSSN === "000000" && /^\d{6}$/.test(formValues.lastSixDigitSSN)){
            errorState.lastSixDigitSSN = { id: 'lastSixDigitSSN', errMsg: 'Enter last six digits of your social security number.', isError: true, warning };
        }
    }

    if (((name === 'phoneNumber') || (final)) && page === 1) {

        if (!formValues.phoneNumber) {
            errorState.phoneNumber = { id: 'phoneNumber', errMsg: 'Enter phone number', isError: true, warning };
        } else if (!isPossiblePhoneNumber(formValues.phoneNumber)) {
            errorState.phoneNumber = { id: 'phoneNumber', errMsg: 'Invalid phone number', isError: true, warning };
        } else {
            errorState.phoneNumber = { id: 'phoneNumber', errMsg: '', isError: false, warning: false };
        }
    }

    if (((name === 'wolChecked') || (final)) && page === 1) {
        if (!formValues.wolChecked) {
            errorState.wolChecked = { id: 'wolChecked', errMsg: 'Click on the checkbox to confirm you agree to the Terms and Conditions', isError: true };
        } else {
            errorState.wolChecked = { id: 'wolChecked', errMsg: '', isError: false };
        }
    }

    //Preferences variables
    let doesAppSupportPreference = (appId === JsonConfig.app_id.LIFE || appId === JsonConfig.app_id.LTC || appId === JsonConfig.app_id.PDCO);
    let isPreferenceCheckBox = (name === 'goPaperless' || name === 'notGoPaperless');

    if (doesAppSupportPreference && (isPreferenceCheckBox || final) && (page === 1 || page === 6 || page === 7)) {
        if (!(formValues.goPaperless || formValues.notGoPaperless)) {
            errorState.goPaperless = { id: 'goPaperless', errMsg: 'This is a required question, please select your preference', isError: true };
            errorState.notGoPaperless = { id: 'notGoPaperless', errMsg: 'This is a required question, please select your preference', isError: true };
        } else {
            errorState.goPaperless = { id: 'goPaperless', errMsg: '', isError: false };
            errorState.notGoPaperless = { id: 'notGoPaperless', errMsg: '', isError: false };
        }
    }

    if ((appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY || appId === JsonConfig.app_id.PDCO) && jwt && ((name === 'wolChecked2') || (final)) && page === 2) {
        if (!formValues.wolChecked2) {
            errorState.wolChecked2 = { id: 'wolChecked2', errMsg: 'Click on the checkbox to confirm you agree to the Terms and Conditions', isError: true };
        } else {
            errorState.wolChecked2 = { id: 'wolChecked2', errMsg: '', isError: false };
        }
    }

    if (((name === 'userName') || (final)) && page === 2) {

        // const spch = /[!#$%^&*()+\=\[\]{};':"\\|,<>\/?~`]+/; //check for special characters not allowed in ISAM
        const spch = /^[A-Za-z0-9_@.-]*$/; //check for special characters allowed in ISAM

        const specialCharAtStart = /^[\W|_]/

        if (!formValues.userName) {
            errorState.userName = { id: 'userName', errMsg: enterUserIdMsg, isError: true, warning };
        } else if (specialCharAtStart.test(formValues.userName.split(" ").join(""))) {
            errorState.userName = { id: 'userName', errMsg: 'User ID cannot start with a special character', isError: true, warning };
        }
        else if (!validateUserName('userName', formValues.userName)) {
            if (!spch.test(formValues.userName)) {
                errorState.userName = { id: 'userName', errMsg: 'Cannot include special characters except ' +
                        '\ @ - _ .', isError: true, warning };
            } else {
                let error = getUserNameValidationError(formValues.userName);
                errorState.userName = { id: 'userName', errMsg: error, isError: true, warning };
            }
        } else if (formValues.usernameAjaxCallfail) {
            errorState.userName = { id: 'userName', errMsg: 'Encountered Error', isError: true, warning: false };
        } else if (!spch.test(formValues.userName)) {
            errorState.userName = { id: 'userName', errMsg: 'Cannot include special characters except '
                    + '\ @ - _ .', isError: true, warning };
        }
        else {
            errorState.userName = { id: 'userName', errMsg: '', isError: false, warning: false };
        }
    }

    if (((name === 'confirmPassword') || (final)) && page === 2) {
        if (!formValues.confirmPassword) {
            errorState.confirmPassword = { id: 'confirmPassword', errMsg: 'Confirm password', isError: true, warning };
        } else {
            if (formValues.confirmPassword.trim() === formValues.password.trim()) {
                errorState.confirmPassword = { id: 'confirmPassword', errMsg: '', isError: false, warning: false };
            } else {
                errorState.confirmPassword = { id: 'confirmPassword', errMsg: 'Confirm password', isError: true, warning };
            }
        }
    }

    if (((name === 'password') || (final)) && page === 2) {
        if (!formValues.password) {
            errorState.password = { id: 'password', errMsg: 'Enter password', isError: true };
        } else if (final && !isValidPasword(formValues.password)) {
            errorState.password = { id: 'password', errMsg: 'Please provide a strong password', isError: true };
        } else {
            errorState.password = { id: 'password', errMsg: '', isError: false };
        }
    }
    
    if ((appId === JsonConfig.app_id.VITALITY || appId === JsonConfig.app_id.PDCO) && jwt && !isPhoneNumberPresent && ((name === 'phoneNumber') || (final)) && page === 2) {
        if (!formValues.phoneNumber) {
            errorState.phoneNumber = { id: 'phoneNumber', errMsg: 'Enter phone number', isError: true, warning };
        } else if (!isPossiblePhoneNumber(formValues.phoneNumber)) {
            errorState.phoneNumber = { id: 'phoneNumber', errMsg: 'Invalid phone number', isError: true, warning };
        } else {
            errorState.phoneNumber = { id: 'phoneNumber', errMsg: '', isError: false, warning: false };
        }
    }

    if (((name === 'authUserId') || (final)) && page === 4) {
     
     
        if (!formValues.authUserId) {
            errorState.authUserId = { id: 'authUserId', errMsg: enterUserIdMsg, isError: true, warning };
        } 
        else {
            errorState.authUserId = { id: 'authUserId', errMsg: '', isError: false, warning: false };
        }
        
    }

    if (((name === 'authPassword') || (final)) && page === 4) {
        if (!formValues.authPassword) {
            errorState.authPassword = { id: 'authPassword', errMsg: 'Enter password', isError: true, warning };
        } 
        else {
            errorState.authPassword = { id: 'authPassword', errMsg: '', isError: false, warning: false };
        }
    }

    return errorState
}

export function validateName(name, value) {

    const re = /[A-Za-z]+/g;  //if there is one or more a-z or A-Z name - valid
    const testString = String(value).split(' ').join(''); //remove spaces for regex test - they are valid
    return re.test(testString) && (value.length <= 50);
}

export function validateEmail(email) {
    // eslint-disable-next-line
    const re = /^[a-zA-Z0-9](([^<>()\[\]\\,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const repeatRegex = /([@#!#$%&'*+-/=?^_`{|])\1/
    return (re.test(String(email).toLowerCase()) && !repeatRegex.test(String(email).toLowerCase()));
}

export function validateUserName(name, value) {
    const re = /[A-Za-z]+/g;  //if there is one or more a-z or A-Z name - valid
    const testString = String(value).split(' ').join(''); //remove spaces for regex test - they are valid
    // return re.test(testString) && (value.length >= 6 && value.indexOf('@') === -1);
    return re.test(testString) && (value.length >= 6);
}

export function getFirstNameValidationError(value) {
    const all_special_and_number = /(\W+)|(\d+)|(\W+d*)|(\d+\W*)/g; //check for mix of only number and special characters - not valid
    let message = '';
    if (String(value).length > 50) {
        message = "First name must be less then 50 characters";
    }
    else if (all_special_and_number.test(String(value).split(" ").join("")))  //don't consider spaces - they are valid
    {
        message = "First name cannot contain all numbers and/or special characters";
    }
    else {
        message = Strings.validations.enter_first_name;
    }
    return message;
}

export function getLastNameValidationError(value) {
    const all_special_and_number = /(\W+)|(\d+)|(\W+d*)|(\d+\W*)/g; //check for mix of only number and special characters - not valid
    let message = '';
    if (String(value).length > 49) {
        message = "Last name must be less then 50 characters.";
    }
    else if (all_special_and_number.test(String(value).split(" ").join("")))  //don't consider spaces - they are valid
    {
        message = "Last name cannot contain all numbers and/or special characters";
    }
    else {
        message = Strings.validations.enter_last_name;
    }
    return message;
}

export function getUserNameValidationError(value) {
    const all_special_and_number = /(\W+)|(\d+)|(\W+d*)|(\d+\W*)/g; //check for mix of only number and special characters - not valid
    let message = '';

    if (String(value).length < 6) {
        message = "User ID must be at least 6 characters.";
    }
    else if (all_special_and_number.test(String(value).split(" ").join("")))  //don't consider spaces - they are valid
    {
        message = "User ID cannot contain all numbers and/or special characters";
    }
    else {
        message = enterUserIdMsg;
    }
    return message;
}

export function validatePassword(password) {

    let result = zxcvbn(password);
    let length = password.length;
    let splChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let checkLength = length >= 9;
    let hasTriple = (/([a-zA-Z0-9!@#$%^&~`*()_+\-=\[\]{};':"\\|,.<>/?])\1\1/).test(password);
    let hasSpecial = splChars.test(password);
    let hasLower = /[a-z]/.test(password);
    let hasUpper = /[A-Z]/.test(password);
    let hasDigit = /[0-9]/.test(password);
    let hasAllPolicies = hasLower && hasSpecial && (!hasTriple) && hasUpper
        && hasDigit && checkLength;

    if (result.score >= 2 && hasAllPolicies) {
        return true;
    } else {
        return false;
    }
}

export function validateDaysInaMonth(day, month, year) {
    let monthString = month == null || year == null ? moment().daysInMonth() : [parseInt(month) < 10 ? "0" + month : month]
    let days = moment(year + "-" + monthString, "YYYY-MM").daysInMonth()
    if(day > days) {
        return false
    }
    return true
}

export default {
    validate, validateName, validateEmail, validateUserName, getFirstNameValidationError, 
    getLastNameValidationError, getUserNameValidationError, validatePassword, validateDaysInaMonth
}