import axios from 'axios';

export function chatBotTokenService (data, chatBotLOB) {

    return new Promise((resolve, reject) => {
        
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': true
        };
        axios.post(
            process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/chatbottoken` : "http://localhost:8080/chatbottoken",
            {
                "appid": data.appId,
                'lob': chatBotLOB
            },
            { headers })
            .then((response) => {
                
                if (response.data.code === "0000") {
                    if(response.data.details){
                        console.log("chatbottoken success");
                        resolve(response.data.details)
                    } else{
                        console.log("chatbottoken failed");
                        reject("")
                    }
                } else {
                    
                    console.log("chatbottoken failed");
                    reject("")
                }
            })
            .catch((err) => reject(err));
    });
}

export default chatBotTokenService;
