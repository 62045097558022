import React, { useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from '@material-ui/core'
import { RadioGroup, Button } from '@manulife/mux'
import { Progress } from '@manulife/mux';
import EncounteredError from '../../components/EncounteredError';
import Utility, { getPhoneNumberLabel } from '../../utils/utility';
import useAdobeDataLayer from '../../hooks/useAdobeDataLayer';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    bodyTextContainer: {
        paddingBottom: "30px"
    },
    radioGroupContainer: {
        marginBottom: "20px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "18px",
        width: "100%",
        display: "block",
        color: "#202336"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px"
    },
    inlineDisplay2: {
        display: "inline-block",
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: "inline-block",
        marginLeft: "8px"

    },
    disclosureStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "12px",
        width: "100%",
        display: "block",
        color: "#202336",
        padding: "10px"
    },
    buttonContainer: {
        display: "flex",
        marginTop: "20px",

        "& button": {
            width: "145px",
            minWidth: "145px",
            height: "60px",
            
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    }
}))

const createLabelText = (nationalPhoneNumber,countryCode) => {
    let mfaValue = []
    let phoneNumberLabel = getPhoneNumberLabel(nationalPhoneNumber,countryCode);
    const textLabel = 'Text me at '+phoneNumberLabel;
    const callLabel = 'Call me at '+phoneNumberLabel;

    mfaValue.push({ value: 'SMS', label: textLabel })
    mfaValue.push({ value: 'VOICE', label: callLabel })

    return mfaValue
}

const MfaPage = props => {
    const adobeDataLayer = useAdobeDataLayer();

    useEffect(() => {
        adobeDataLayer.pageLoad('step3');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
        Utility.scrollToTop();
    }, [])

    const classes = useStyles()

    const changeHandler = (name, value) => {
        props.setFormValues({ ...props.setPhoneNumber, [name]: value })
    }

    return (
        <div>
            <Grid container spacing={0}>  
                {
                    props.systemErrorCount > 0 &&
                        <EncounteredError systemErrorCount={props.systemErrorCount}/>
                }
                <Grid item xs={12} className={classes.bodyTextContainer}>
                    <span className={classes.labelStyle} >
                        Please choose one of the contact methods below to receive a confirmation code: Message and  data rates may apply.
                    </span>
                </Grid>
                <Grid item xs={8} className={classes.radioGroupContainer}>
                    <RadioGroup
                        name="Radio"
                        onChange={e => changeHandler("mfaType", e)}
                        selected={props.formValues.mfaType}
                        customStyle={
                            {
                                labelStyle: {
                                    fontSize: '14px',
                                    fontWeight: "400",   
                                    margintTop: "0px !important",                                 
                                    marginBottom: "22px"
                                },
                                radioStyle: {
                                    margintTop: "0px !important",  
                                    marginBottom: "22px"
                                }
                            }
                        }
                        values={createLabelText(props.nationalPhoneNumber,props.countryCode)}
                    />
                </Grid>
                <Grid item md={9} xs={12} className={classes.buttonContainer}>
                    <Button
                        disabled={props.isAwaitingApiCall}
                        onClick={e => props.onClick(3)}
                        name="MFAGenerateButton"
                        id="MFAGenerateButton"
                        saving={props.isAwaitingApiCall}
                    >Next</Button>
                </Grid>
            </Grid>

        </div>
    )
}

export default MfaPage
