import React, {useEffect} from "react";
import { Button } from "@manulife/mux";
import { Grid, makeStyles } from "@material-ui/core";
import Utility from "../../utils/utility";

//const PAGE_HEADER_TEXT = "Welcome to the policy activation process";
const PAGE_BODY_TEXT_P1 = "Your John Hancock life insurance policy has been issued, and we are honored you have chosen us for your insurance needs. In order to place your policy in-force, your signature and the initial premium payment are required."
const PAGE_BODY_TEXT_P2 = "Please plan to complete each step once you begin. Once these steps are complete, your agent will be notified of your successful policy activation. You will continue to receive email notifications to the email address you provided on your application.";
const PAGE_BODY_TEXT_BOLD = "This process will take 10 minutes. If you are the payor for the policy, please have your payment information ready.";

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    Header: {  
        fontWeight: "600",
        fontSize: "48px",
        lineHeight: "60px",
        margin: 0,
        paddingBottom:"60px",
        color: "#202336"
    },
    GridSeperation: {
        paddingBottom: "25px"
    },
    PageText: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "22px",
        lineHeight: "30px",
        color: "#282B3E",
        margin: 0,
      },
      strongText: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "22px",
        lineHeight: "32px",
        color: "#282B3E",
        margin: 0,
       },
      mb40: {
        marginBottom: "40px"
      },
      mb20: {
        marginBottom: "20px"
      },
      bh: {
        paddingLeft: "35px"
      },
      pl60: {
        paddingLeft: "34px"
      },
      li:{
        lineHeight:"38px"
      },
    buttonContainer: {
        display: "flex",
        marginTop: "20px",

        "& button": {
            width: "157px",
            minWidth: "157px",
            height: "60px",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    }
}))


const WelcomePage = props => {

    const { setPage, invitationResponseData, setAlreadyRegistered, setSessionTimeoutEnable,setAnnuities } = props;
    const classes = useStyles();

    
    useEffect(()=> {
        setSessionTimeoutEnable(true);
        Utility.scrollToTop();
    }, [])

    const getStarted = () => {
        if (invitationResponseData.code === "9035") {
            setAlreadyRegistered(true);
        } else if (invitationResponseData.code === "3000") {
            setPage(4)
        }else if (invitationResponseData.code === "1001") {
            setPage(1)
        }
        else if (invitationResponseData.code === "9056") {
            console.log("redirectUrl==", invitationResponseData.details.registrationlink);
            let url = invitationResponseData.details?.registrationlink;
            if(url?.startsWith("https://crverifyidentity.registration-dev.johnhancock.com") || 
            url?.startsWith("https://crverifyidentity.registration-tst.johnhancock.com") ||
            url?.startsWith("https://crverifyidentity.registration-uat.johnhancock.com") ||
            url?.startsWith("https://crverifyidentity.registration.johnhancock.com") ||
            url?.startsWith("http://localhost:3001")){
            window.open(url, "_self");
        }
        }else if (invitationResponseData.code === "9054"){
            setPage(-1);
            setAnnuities({isVisible: true, from: "AuthenticationPage"});
        }else{
            setPage(1)
        }
    }
    return (
        <div>
            <Grid container >
                <Grid item xs={12} md={8} id="page-header">
                    <h1 className={classes.Header}>Welcome, {invitationResponseData.details.firstName === undefined  ? invitationResponseData.details.firstname :invitationResponseData.details.firstName }</h1>
                </Grid>
                <Grid item xs={12} md={9} className={classes.GridSeperation}>
                    <p style={{marginBottom:"40px"}} className={classes.PageText}>{PAGE_BODY_TEXT_P1}</p>
                   <div>
                        <strong  className={classes.strongText}>To access the signature screens, you will first need to create an online profile.</strong>
                        <p style={{marginBottom:"40px",marginTop:"20px"}} className={classes.PageText}>The user name and password you select will give you access to the Life Customer Storefront, where you can view policy details, submit policy changes, access policy documents, and much more.</p>
                    </div>
                    <div>
                        <strong className={classes.strongText}>Here are a few additional details to make note of before starting:</strong>
                        <ul>
                            <li style={{lineHeight:"38px"}} className={classes.PageText}>Through this process, you will electronically sign outstanding form(s), and we will electronically deliver your policy to you</li>
                           
                            <li  style={{lineHeight:"38px"}} className={classes.PageText}>If you are the payor on this policy, you will need to complete payment details after applying your signature</li>
                            
                            <li  style={{lineHeight:"38px"}} className={classes.PageText}>Please complete each section registration, signature and payments (if applicable) in a single session</li>
                            
                            <li  style={{lineHeight:"38px"}} className={classes.PageText}>The full policy activation process will take approximately 10–15 minutes</li>
                        </ul>
                        <p className={classes.PageText}>Once all required steps have been completed, your insurance agent will receive notification that your policy is active. Please contact your agent if you do not wish to complete this process electronically.</p>
                        <p style={{marginBottom:"40px",marginTop:"20px"}} className={classes.PageText}>When you’re ready to begin, click the button below.</p>
                    </div>
                </Grid>

                <Grid item xs={12} md={9} className={classes.buttonContainer}>
                    <Button
                        name="next button"
                        data-testid="WelcomePage-nextButton"
                        onClick={getStarted}
                        id="nextbutton"
                    >
                        Get Started
                    </Button>
                </Grid>

            </Grid>
        </div>
    )
}

export default WelcomePage