import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { TextInput, INPUT_VARIANT, Button, ActionButton, Checkbox, RadioGroup } from '@manulife/mux';
import { makeStyles } from "@material-ui/core/styles"
import { Eye1, Eye2 } from '@manulife/mux-cds-icons';
import { PasswordStrengthValidator, PasswordMeter } from "../../components/"
import { validate } from '../../components/PageBody/validate'
import { ajaxUsername } from "../../api"
import { Progress } from '@manulife/mux';
import { Tooltip } from '@manulife/mux';
import { Info1 } from '@manulife/mux-cds-icons';
import EcounteredError from '../../components/EncounteredError';
import JsonConfig from '../../utils/jsonConfig.json';
import validatePasswordAjax from '../../api/validatePasswordAjax';
import { hasNumbers, hasUpper, hasLower, hasSpecial, hasEight, isValidPasword } from '../../utils/ValidatePassword';
import Utility, { doNothing } from '../../utils/utility';
import { getQueryParams } from '../../utils';
import { ElectronicTCModalPage } from '..';
import { Modal, BREAKPOINTS } from '@manulife/mux';
import { ReactComponent as LeafLogo } from '../../images/Leaf.svg';
import useAdobeDataLayer from '../../hooks/useAdobeDataLayer';
import InputError from '../../components/InputError/InputError';
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import '../UserInfoPage/phoneInput.css';


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const gridSeparation1 = "gridSeparation1";
const myinput = "myinput";
const MANULIFE_FONT = "Manulife JH Sans"
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeperation: {
        paddingBottom: "40px"
    },
    gridLowSeperation: {
        paddingBottom: "10px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "14px",
        width: "100%",
        display: "block",
        color: "#5E6073",
        lineHeight: "20px"
    },
    placeHolderStyle: {
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "14px",
        color: "#a9a9a9"

    },
    gridLowMargin: {
        margin: "0px",
        paddingBottom: "5px !important"
    },
    gridNoMargin: {
        margin: "0px",
        paddingBottom: "0px !important"
    },
    InLine: {
        display: "inline-block",
        width: "100%"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px"
    },
    inlineDisplay2: {
        display: displayStyle,
        marginTop: "10px",
        marginBottom: "10px",
    },
    InLineLabel: {
        display: displayStyle,
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "16px",
        paddingRight: "5px"
    },
    showIcon: {
        display: displayStyle,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    signInUnderline: {
        fontFamily: font,
        fontSize: "16px",
        color: "black",
        textDecorationColor: "#0000C1",
        lineHeight: "20px"
    },
    underlineWrapper: {
        display: "flex",
        paddingTop: "25px"
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: displayStyle,
        marginLeft: "8px"

    },
    ctaWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: "40px",
        marginTop: "20px",
        [theme.breakpoints.only("xs")]: {
            flexDirection: "column",
        },
        "& a": {
            marginBottom: "1rem",
            flexShrink: 0,
            marginRight: "1rem",
            // marginBottom: 0,            
            [theme.breakpoints.only("xs")]: {
                width: "100%",
                marginLeft: "1rem"
            }
        },
        "& button": {
            width: "145px",
            height: "60px",
            minWidth: "0px",
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }

    },
    errorPasswordLabel: {
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        margin: "-10px 0px 10px"
    },
    flexDisplay: {
        display: "flex"
    },    
    termsAndConditions: {
        display: "flex",
        color: "#282B3E",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26px",
        alignItems: "center"
    },    
    blueUnderline: {
        textDecoration: "none",
        borderBottom: "2.0px solid #0000C1",
        color: "black",
        fontWeight: "600"
    },    
    errorMessage: {
        display: "flex",
        color: "#A00E18",
        marginTop: "0.5em",
        "&:before": {
            content: "''",
            display: displayStyle,
            flexShrink: 0,
            width: "29px",
            height: "17px",
            marginTop: "2px",
            background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',
        }
    },
    
    goPaperlesshead:{
        lineHeight: "24px"       
    },  
    goPaperLessHeading: {
        fontSize: "26px",
        fontFamily: MANULIFE_FONT,
        fontWeight: "600",
        lineHeight: "36px",      
        color: "#202336",
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperlessContentBody: {
       
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperLessSubHeadingBody:{
       marginTop: "-2%",
       lineHeight: "36px"
    },
    logoStyles: {
        textAlign: "center",
        "& svg": {
            maxWidth: "100%",
            marginTop: "24px",
            position: "relative",
            top: "5px"
        },
        [theme.breakpoints.up("sm")]: {
            paddingRight: "10px",
            textAlign: "left"
        }
    },
    goPaperLessSubHeading: {
        fontSize: "18px",
        fontFamily: MANULIFE_FONT,
        fontWeight: "600",
        lineHeight: "24px",       
        color: "#000000",
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperLessContent: {
        fontSize: "16px",
        fontFamily: MANULIFE_FONT,
        fontWeight: "400",
        lineHeight: "24px",    
       
        color: "#000000",
        letterSpacing: "0px",
        textAlign: "left"
    },
   
    tnCblock: {
        gap: "10px"
    },
    termsandconditionsHeading: {
        fontSize: "26px",
        fontFamily: MANULIFE_FONT,
        fontWeight: "600",
        lineHeight: "36px",
        marginTop: "27px",
        color: "#202336",
        letterSpacing: "0px",
        textAlign: "left"
    },
    gridAlignment: {
        display: "flex",
        marginRight: "60px",
        // alignSelf: "stretch"
    },
}))

let { appId, jwt } = getQueryParams();

const CredentialsPage = props => {
    const adobeDataLayer = useAdobeDataLayer();

    useEffect(() => {
        adobeDataLayer.pageLoad('step2');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {setSessionTimeoutEnable, formValues, setFormValues, errorState, setErrorState, systemErrorCount, setSystemErrorCount, isAwaitingApiCall, setIsAwaitingApiCall, onClick, setPaperlessTCModalOpen, setPage,setPhoneType,setCountryCode,setNationalPhoneNumber } = props;
    const [isCheckingPassStrength, setCheckingPassStrength] = useState(false);
    const [isContextWordsPassed, setIsContextWordsPassed] = useState(false);
    const [isUsernameLoading, setUsernameLoading] = useState(false);
    const [isSequentialPassed, setIsSequentialPassed ] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [isUnderstand, setisUnderstand] = useState(false)

    //code added for PDCO appid setpreferences
    const checkisUnderstand = (value) => {      
        setisUnderstand(true); 
        setPaperlessTCModalOpen(false);     
    };

    const paperlessModalOpen = (event) => { 
        event && event.preventDefault()     
        setPaperlessTCModalOpen(true);
    };

    const paperlessModalClose = () => {
        setPaperlessTCModalOpen(false);
    };

    useEffect(()=> {
        setSessionTimeoutEnable(true);           
        Utility.scrollToTop();
    }, [])

    const classes = useStyles()
    
    const [allValues, setAllValues] = useState({
        passwordVisible: false,
        confirmPasswordVisible: false
    });
    const [passwordValidated, setPasswordValidated] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);
    const changeHandler = (name, value) => {
        //code added to check for checkbox for gopaperless
        
        value = value || "";
        let newFormValues = {};
        if (name === 'goPaperless') {
            newFormValues = { ...props.formValues, [name]: value, "notGoPaperless": false };
        }
        else if (name === 'notGoPaperless') {
            newFormValues = { ...props.formValues, [name]: value, "goPaperless": false };
        } else if (name === 'phoneNumber') {
            const phoneNumber = parsePhoneNumber(value) || "";
            newFormValues = { ...formValues, [name]: value };
            setNationalPhoneNumber(phoneNumber?.nationalNumber);
            setPhoneType(phoneNumber?.country)
            setCountryCode(phoneNumber?.countryCallingCode);
        } else{
            newFormValues = { ...formValues, [name]: typeof value=='string' ? value.trim() :value};
        }
        setFormValues(newFormValues)
        setErrorState(validate(newFormValues, name, errorState, false, 2, null, true));
    }
    const tooltipText = <div><p>You can use your email or a unique username for your User ID.</p><p></p>
        <p>Your User ID will be your login for any additional John Hancock accounts you may have, including
            life insurance, long-term care, Vitality, and/or mutual fund IRA/Roth IRA.
        </p>
    </div>
    const tooltipText2 = <div><p>
        Passwords must have a minimum of eight characters and contain a lowercase letter, uppercase letter, number, and special character (ex: ! @ #).<br/><br/>
        Passwords cannot include your name, User ID, commonly guessed words (ex: password, insurance), sequential characters (ex: ABCD, 1234), or use a character more than three times (ex: AAA, A1A2A3). 
        </p>
    </div>
    const tooltipTextElectronicDelivery = "Not all communications, notices and disclosures, are currently available to be delivered electronically, so you may still receive some paper mail until enhancements are made."
    
    const handleShow = (name) => {
        setAllValues({ ...allValues, [name]: !allValues[name] })
        //
    }
    function onUsernameResponse(response, error){
        setUsernameLoading(false);
    }

    function validateAndCallAjax(){
        setErrorState({...props.errorState, userName: { ...props.errorState.userName, warning: false }})
        if(!(errorState.userName.isError || formValues.userName === '')){
            setUsernameLoading(true);
            ajaxUsername(formValues.userName, formValues.emailAddress, formValues.firstName, formValues.lastName, setErrorState, formValues, setFormValues, setShowSignIn, errorState, systemErrorCount, setSystemErrorCount, onUsernameResponse);
        }
    }


    function onPasswordValidatedResponse(response, error){
        
        setCheckingPassStrength(false);
        setPassword(formValues.password);
        if(error){
            setSystemErrorCount(systemErrorCount + 1);
            return;
        }
        let data = response.data;
        if (data.code === "0000") {
            setSystemErrorCount(0);
            setIsContextWordsPassed(true);
            setIsSequentialPassed(true);
            setIsValidPassword(true);
            checkAndSetUIPasswordErrors(true); // validatepassword success true
        } else if(data.code === "9001" || data.code === "9003" || data.code === "9018"){
            setSystemErrorCount(systemErrorCount + 1);
            setIsContextWordsPassed(false);
        } else if( data.code === "9020"){
            setIsSequentialPassed(true); // when password is invalid but context/sequential is passed remove default value
            setIsContextWordsPassed(true);
            if(isValidPasword(formValues.password)){
                setIsValidPassword(false);
                setErrorState({...errorState, password: { id: 'password', errMsg: data.message, isError: true, errorCode: data.code}});
            } else {
                checkAndSetUIPasswordErrors(true); // validatepassword success true
            }
        } else if(data.code === "2001" || data.code === "2002" ||
                 data.code === "2004"){ //Context word detected like userid, company name etc.
            setErrorState({...errorState, password: { id: 'password', errMsg: data.message, isError: true, errorCode: data.code}});
            setIsContextWordsPassed(false);
            setIsSequentialPassed(true);
            setIsValidPassword(false);
        } else if (data.code === "2003") { //Sequential numbers or letters detected like 1234, abcd, 1111, aaaa, etc.
            setErrorState({...errorState, password: { id: 'password', errMsg: data.message, isError: true, errorCode: data.code}});
            setIsSequentialPassed(false);
            setIsContextWordsPassed(true);
            setIsValidPassword(false);
        } else if (data.code === "400") {
            setErrorState({...errorState, password: { id: 'password', errMsg: "Invalid Request", isError: true, errorCode: data.code}});
            setIsValidPassword(false);
        }
    }

    function checkAndSetUIPasswordErrors(validPasswordSuccess){
        let password = formValues.password;
        let errors = [];
        errors.push(hasNumbers(password));
        errors.push(hasUpper(password));
        errors.push(hasLower(password));
        errors.push(hasSpecial(password));
        errors.push(hasEight(password));
        errors = errors.filter((item)=> item != null);

        if(errors.length > 0){
            let errorMsg = errors.length === 1 && validPasswordSuccess ? errors[0] : "Please be sure to fulfill all password requirements.";
            setErrorState({...errorState, ...{password: { id: 'password', errMsg: errorMsg, isError: true }}});
        }
    }

    function onPasswordCheck() {
            let password = formValues.password
            let checkPassword = formValues.password.trim();
            if(!checkPassword){
                setPassword(checkPassword);
                setIsSequentialPassed(false); // when password is invalid but context/sequential is passed remove default value
                setIsContextWordsPassed(false);
                setIsValidPassword(false);
                return;            
            }
            if(!isCheckingPassStrength){
                let payload = {
                    appid: appId,
                    userid: formValues.userName.trim(),
                    password: password,
                    firstname: formValues.firstName.trim(),
                    lastname: formValues.lastName.trim(),
                    guid: props.guid
                }
                setPasswordValidated(isValidPasword(password))
                setCheckingPassStrength(true)
                validatePasswordAjax(payload, onPasswordValidatedResponse);
            }
    }

    useEffect(()=> {

        // let loginUrlEle = document.getElementById("login-url");
        if(showSignIn){
            setLoginUrl();
        }

    }, [showSignIn])

    function setLoginUrl(){
        // make sure to add/update new Login URLs here if introduced.
        let validRedirectUrls = [
            "https://digital-uat.customer.johnhancock.com/login",
            "https://jhltc--qa.sandbox.my.site.com/customer",
            "https://jhcustomerone--qa.sandbox.my.site.com/jhlifecustomer/s",
            "https://stage.johnhancockvitality.com",

            "https://digital.customer.johnhancock.com/login",
            "https://ltc.customer.johnhancock.com",
            "https://life.customer.johnhancock.com",
            "https://www.johnhancockvitality.com"
        ];

        
        let loginUrlVariable = "REACT_APP_LOGIN_URL_"+JsonConfig.login_url_postfix[appId];
        let redirectUrl = process.env[loginUrlVariable] || JsonConfig.static_redirect_url.default;
        
        // only secure sites will be redirected. This code is added for snyk fix.
        if(validRedirectUrls.includes(redirectUrl)){
            let loginUrlEle = document.getElementById("login-url");
            loginUrlEle.href = redirectUrl;
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={0}>
                {
                    systemErrorCount > 0 &&
                        <EcounteredError systemErrorCount={systemErrorCount}/>
                }
                <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.userName.isError && !errorState.userName.warning ? " error" : "")}>
                    <div className={classes.flexDisplay}>
                        <span><label className={classes.labelStyle} >User ID</label></span>
                        <span><Tooltip title={tooltipText} bubbleWidth={290}
                            customStyle={{
                                rootStyle: {
                                    paddingLeft: "5px",
                                    marginTop: "-5px"
                                }
                            }}
                        ><Info1 color="#282B3E" />
                        </Tooltip></span>
                    </div>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="userName"
                        value={formValues.userName}
                        onKeyDown={doNothing}
                        onChange={e => changeHandler("userName", e)}
                        required={true}                        
                        icon={<Progress 
                            doShow={isUsernameLoading}
                            isFloating={false} 
                            customStyle={{
                            spinnerStyle: { 
                                height: "25px",
                                width: "25px",
                                marginRight: "10px"
                            }
                        }} />}
                        placeholder="Your desired username"
                        onBlur={event => validateAndCallAjax()}
                        customStyle={{
                            rootStyle: {
                                width: "100%",

                            },
                            inputStyle: {
                                padding: '0px'
                            }
                        }}
                        name="userName" />
                    {
                        errorState.userName.isError &&
                        <InputError errorState={errorState} inputId="userName" showIcon={true}/>
                    }
                </Grid>
                <Grid item md={9} xs={12} className={+gridSeparation1+" mb0 "+myinput+(errorState.password.isError ? " error" : "")}>
                    <div className={classes.flexDisplay}>
                        <span><label className={classes.labelStyle} >Password</label></span>
                        <span><Tooltip title={tooltipText2} bubbleWidth={290}
                            customStyle={{
                                rootStyle: {
                                    paddingLeft: "5px",
                                    marginTop: "-5px"
                                }
                            }}
                        ><Info1 color="#282B3E" />
                        </Tooltip></span>
                    </div>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="password"
                        type={allValues.passwordVisible ? "text" : "password"}
                        required={true}
                        placeholder="Your desired password"
                        value={formValues.password}
                        onKeyDown={doNothing}
                        className={classes.InLine}
                        onChange={e => {
                            if(passwordValidated) setPasswordValidated(false);
                            changeHandler("password", e)}}
                        onBlur={onPasswordCheck}
                        onFocus={()=> {
                            setPasswordValidated(false);
                            setErrorState({...errorState, ...{password: { id: 'password', errMsg: "", isError: false }}});
                        }}
                        customStyle={{
                            rootStyle: {
                                width: "100%",

                            },
                            inputStyle: {
                                padding: '0px'
                            }
                        }}
                        name="password"
                        isIconClickable={true}
                        icon={<ActionButton
                                ariaLabel="Edit"
                                icon={allValues.passwordVisible ? <Eye2 color="#282B3E" />
                                    : <Eye1 color="#282B3E" />
                                }
                                onClick={e => handleShow("passwordVisible")}
                            />
                        } />
                </Grid>
                
                <Grid item className={classes.gridSeperation} xs={12}>
                    <PasswordStrengthValidator 
                        password={password} 
                        onPasswordCheck={onPasswordCheck}
                        errorState={errorState} 
                        isCheckingPassStrength={isCheckingPassStrength} 
                        passwordValidated={passwordValidated}
                        isContextWordsPassed={isContextWordsPassed}
                        isSequentialPassed={isSequentialPassed}
                        isValidPassword={isValidPassword}
                    />
                </Grid>
                <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.confirmPassword.isError && !errorState.confirmPassword.warning ? " error" : "")}>
                    <label className={classes.labelStyle} >
                        Confirm Password
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="confirmPassword"
                        type={allValues.confirmPasswordVisible ? "text" : "password"}
                        value={formValues.confirmPassword}
                        onKeyDown={doNothing}
                        onChange={e => changeHandler("confirmPassword", e)}
                        onBlur={()=> setErrorState({...errorState, confirmPassword: { ...errorState.confirmPassword, warning: false }})}
                        placeholder="Re-enter desired password"
                        customStyle={{
                            rootStyle: {
                                width: "100%"
                            },
                            inputStyle: {
                                padding: '0px'
                            }
                        }}
                        name="confirmPassword" 
                        isIconClickable={true}
                        icon={<ActionButton
                                ariaLabel="Edit"
                                icon={allValues.confirmPasswordVisible ? <Eye2 color="#282B3E" />
                                    : <Eye1 color="#282B3E" />
                                }
                                onClick={e => handleShow("confirmPasswordVisible")}
                            />}
                        />
                    {
                        errorState.confirmPassword.isError && 
                        <InputError errorState={errorState} inputId="confirmPassword" showIcon={true}/>
                    }
                </Grid>
                {
                !props.isPhoneNumberPresent &&     
                    <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.phoneNumber.isError ? " error" : "")}>
                        <label htmlFor='phoneNumber' className={classes.labelStyle} >
                            Phone number
                        </label>
                        <PhoneInput
                            id="phoneNumber"
                            defaultCountry={"US"}
                            layout="second"
                            countryCallingCodeEditable={true}
                            placeholder="Your phone number"
                            onKeyDown={doNothing}
                            data-testid="UserInfo-phoneNumber"
                            required={true}
                            callouts={true}
                            onBlur={()=> setErrorState({...errorState, phoneNumber: { ...errorState.phoneNumber, warning: false }})}
                            name="phoneNumber"
                            onChange={e => {changeHandler("phoneNumber", e)}}
                        />
                        {
                            errorState.phoneNumber.isError &&
                            <InputError errorState={errorState} inputId="phoneNumber" showIcon={true}/>
                        }
                    </Grid>
                }
                                
                {
                    (appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY || appId === JsonConfig.app_id.PDCO) && jwt &&
                    <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.wolChecked2.isError ? " error" : "")}>
                        <div className={classes.termsAndConditions}>
                            <Checkbox
                                id="wolChecked2"
                                data-testid="UserInfo-wolChecked2"
                                required={true}
                                customStyle={{
                                }}
                                checked={formValues.wolChecked2}
                                errors={[errorState.wolChecked2]}
                                onChange={e => changeHandler("wolChecked2", e)}
                                name="wolChecked2"
                            />
                            <div className={classes.waiverText}><label htmlFor='wolChecked2'>By creating an account, I agree to John Hancock's <a className={classes.blueUnderline} rel="noopener noreferrer" href="https://www.johnhancock.com/legal.html" target="_blank" >Terms and Conditions</a></label></div>
                        </div>
                        {
                            errorState?.wolChecked2?.errMsg &&
                            <InputError errorState={errorState} inputId="wolChecked2" showIcon={true}/>
                        }
                    </Grid>
                }

                <Grid item md={12} xs={12} className={classes.ctaWrapper}>
                {showSignIn && <a id="login-url" className={classes.signInUnderline} href="https://johnhancock.com"><b>Sign in instead</b></a>}
                    <Button
                        onClick={() => {
                            /* if(!props.isPhoneNumberPresent){
                                // UI should always send number without special characters, only digits allowed
                                props.setFormValues({...formValues, phoneNumber: formValues.phoneNumber.replace(/\D/g, '')});
                            } */
                            onClick(2, passwordValidated && isContextWordsPassed && isSequentialPassed && !isCheckingPassStrength && isValidPassword);
                        }}
                        saving={isAwaitingApiCall}
                        name="RegisterButton"
                        id="RegisterButton"
                        disabled={showSignIn || isAwaitingApiCall}
                    >Next</Button>
                    
                </Grid>
            </Grid>

            <Modal isOpen={props.isPaperlessTCModalOpen} onClose={paperlessModalClose} customStyle={{
                 modalStyle: {
                    height: 'auto',
                    width: '720px',
                    media: [{
                        maximum: BREAKPOINTS.PHONE_PORTRAIT_MED,
                        width: '85%'
                    }]
                }
            }} ariaLabel="Timeout Modal" ariaLabelledBy="heading" ariaDescribedBy="content">
                <ElectronicTCModalPage />
                <Button id="IUnderstandButton"
                        data-testid="UserInfo-IUnderstandButton"
                        name="IUnderstandButton"
                        customStyle={{
                            buttonStyle: {
                                width: "150px",
                                height: "60px"
                            }
                        }} disabled={props.isAwaitingApiCall} onClick= { e => checkisUnderstand(e)}><div className={classes.confirmTextStyle}>I understand</div></Button>                 

            </Modal>  
        </div>
    )
}

export default CredentialsPage

