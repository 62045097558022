import React from 'react';
import PageBody from './PageBody';

function PageBodyWrapper({ setDisplayPDCOBanner }) {
  return (
    <div data-testid="PageBody-root">
      <PageBody page={0} setDisplayPDCOBanner={setDisplayPDCOBanner} />
    </div>
  );
}

export default PageBodyWrapper;