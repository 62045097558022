import axios from 'axios';
import { deleteUser } from '.';
import JsonConfig from '../utils/jsonConfig.json';
import { getQueryParams } from '../utils';
import Utility from '../utils/utility';
const authenticateUser = (formValues, guid, setGuid, setIsAwaitingApiCall, setErrorScenario, authErrorCountHook, setFormValues, setErrorType, setpasswordUpdateRequired, setAccountUnlock, setAppid, setUnlockToken, setIsamPasswordError, setProductList, setAnnuities, setPage, systemErrorCountHook, setAuthenticationFlow, setPaperlessOrigin,setPhoneType,setCountryCode,setNationalPhoneNumber) => {

  const [authErrorCount, setAuthErrorCount] = authErrorCountHook;
  const [systemErrorCount, setSystemErrorCount] = systemErrorCountHook;
  
  
  let { appId, jwt } = getQueryParams();


  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true
  };
  setIsAwaitingApiCall(true)

  axios.post(
    process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/authenticate` : "http://localhost:8080/authenticate",
    {
      "UserID": formValues.authUserId,
      "Password": formValues.authPassword,
      "Guid": guid,
      "Token": (appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY || appId === JsonConfig.app_id.PDCO) && jwt ? jwt : "" 
    },
    { headers }).then(response => {
      setIsAwaitingApiCall(false);
      if(response.data.code === "3001"){ // Authentication Success
        setAuthErrorCount(0)
        setIsamPasswordError(false)
        setSystemErrorCount(0);
        setGuid(response.data.details.requestid)
        const phone=response.data.details.phonenumber;
        const phoneNumber=Utility.phoneNumberParser(phone);
        setNationalPhoneNumber(phoneNumber?.nationalPhoneNumber);
        setPhoneType(phoneNumber?.countryType);
        setCountryCode(phoneNumber?.countryCode);
        setFormValues({ ...formValues, phoneNumber: phone });
        if (appId === JsonConfig.app_id.PDCO && jwt) {
          setPaperlessOrigin("authentication");
          setPage(6);
        } else {
          setPage(2);
        }
      }else if(response.data.code === "9041"){
        setAuthErrorCount(1)
        setAuthenticationFlow(true);
        setPage(4);
        setFormValues({ ...formValues, authUserId: '', authPassword: '' })
      } else if (response.data.code === "9042") {
          setAuthErrorCount(0)
          setIsamPasswordError(false)
          deleteUser(guid, "Authentication")
          setErrorScenario(true)
      } else if (response.data.code === "9034") {
        setIsamPasswordError(true)
        setAuthenticationFlow(true);
        setPage(4);
        setFormValues({ ...formValues, authUserId: '', authPassword: '' })
      } else if (response.data.code === "9054") {
        // When Annuities reserved flag is true        
        setAuthErrorCount(0)
        setIsamPasswordError(false)
        setPage(-1);
        setAnnuities({isVisible: true, from: "AuthenticationPage"});
        setFormValues({ ...formValues, authUserId: '', authPassword: '' });
      } else if(response.data.code === "9052" || response.data.code === "9043"){
        setSystemErrorCount(systemErrorCount+1);
      } else if (response.data.code === "9044") {        
        setAuthErrorCount(0)
        setIsamPasswordError(false)
        if(response.data.details.appid === "2320147"){ // In case of DTC then show "Call-1800" Page
          setErrorScenario(true)
        } else{ // or else enable Unlock account self service
          setAppid(response.data.details.appid)
          setUnlockToken(response.data.details.token)
          setAccountUnlock(true)
        }
      } else if (response.data.code === "9045") {
        setpasswordUpdateRequired(true)
      } else{
        setSystemErrorCount(0);
      }
    }).catch(error => {
      console.log("Error ========>", error);
      setIsAwaitingApiCall(false)
      if (error.message === "Network Error") {
        setErrorType("Technical")
      } else{
        setSystemErrorCount(systemErrorCount+1);
      }
    });
};

export default authenticateUser;