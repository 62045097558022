import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Info1, Caution } from '@manulife/mux-cds-icons';
import { Grid } from '@material-ui/core';
import { Eye1, Eye2 } from '@manulife/mux-cds-icons';
import { TextInput, INPUT_VARIANT, Button, ActionButton, Checkbox } from '@manulife/mux';
import { Tooltip } from '@manulife/mux';
import { validate } from '../../components/PageBody/validate'
import { Progress } from '@manulife/mux';
import { MessageCard, MESSAGE_VARIANT } from '@manulife/mux';
import EncounteredError from '../../components/EncounteredError';
import { getQueryParams } from '../../utils';
import { ElectronicTCModalPage } from '..';
import { Modal, BREAKPOINTS } from '@manulife/mux';
import { ReactComponent as LeafLogo } from '../../images/Leaf.svg';
import JsonConfig from '../../utils/jsonConfig.json';
import { doNothing, scrollToTop } from '../../utils/utility';
import InputError from '../../components/InputError/InputError';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const MANULIFE_FONT = "Manulife JH Sans"
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "40px"
    },
    buttonContainer:{
        paddingBottom: "40px",
        marginTop: "20px",
        "& button": {
            width: "150px",
            minWidth: "145px",
            height: "60px",            
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    subText: {
        fontFamily: font,
        fontSize: "22px",
        color: "#282B3E",
        lineHeight: "28px"
    },
    InLine: {
        display: displayStyle,
        width: "100%"
    },
    gridNoMargin: {
        margin: "0px",
        paddingBottom: "0px !important"
    },
    showIcon: {
        display: displayStyle,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "13px",
        width: "100%",
        display: "block",
        color: "#282B3E",
        lineHeight: "20px"
    },
    headerMargin: {
        marginTop: "49px"
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    },
    signInUnderline: {
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "500",
        color: "#282B3E",
        textDecorationColor: "#0000C1",
        lineHeight: "28px",
        fontStyle: "normal"
    },
    inlinePadding: {
        paddingRight: "6em"
    },
    footnoteText: {
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "normal",
        color: "#282B3E",
        lineHeight: "24px",
        fontStyle: "normal"
    },
    errorPasswordLabel: {
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
        margin: "-10px 0px 1.5em"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px",
        width: "64%",
        float: "right"
    },
    inlineDisplay2: {
        display: displayStyle,
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: displayStyle,
        marginLeft: "8px"
    },
    linkStyles: {
        fontWeight: "500",
        color: "#282B3E",
        textDecorationColor: "#0000c1",
        textUnderlinePosition: "under"
    },
    termsAndConditions: {
        display: "flex",
        color: "#282B3E",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26px",
        alignItems: "center"
    },  
    flexDisplay: {
        display: "flex"
    },
    goPaperlesshead:{
        lineHeight: "24px"       
    },
    goPaperLessHeading: {
        fontSize: "26px",
        fontFamily: MANULIFE_FONT,
        fontWeight: "600",
        lineHeight: "36px",      
        color: "#202336",
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperlessContentBody: {
       
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperLessSubHeadingBody:{
       marginTop: "-2%",
       lineHeight: "36px"
    },
    logoStyles: {
        textAlign: "center",
        "& svg": {
            maxWidth: "100%",
            marginTop: "24px",
            position: "relative",
            top: "5px"
        },
        [theme.breakpoints.up("sm")]: {
            paddingRight: "10px",
            textAlign: "left"
        }
    },
    goPaperLessSubHeading: {
        fontSize: "18px",
        fontFamily: MANULIFE_FONT,
        fontWeight: "600",
        lineHeight: "24px",       
        color: "#000000",
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperLessContent: {
        fontSize: "16px",
        fontFamily: MANULIFE_FONT,
        fontWeight: "400",
        lineHeight: "24px",    
       
        color: "#000000",
        letterSpacing: "0px",
        textAlign: "left"
    },
    gridAlignment: {
        display: "flex",
        marginRight: "60px",
        // alignSelf: "stretch"
    },
    blueUnderline: {
        textDecoration: "none",
        borderBottom: "2.0px solid #0000C1",
        color: "black",
        fontWeight: "600"
    },
    errorMessage: {
        display: "flex",
        color: "#C14A36",
        marginTop: "0.5em",
        "&:before": {
            content: "''",
            display: displayStyle,
            flexShrink: 0,
            width: "29px",
            height: "17px",
            marginTop: "2px",
            background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',
        }
    },       
}));

let { appId, jwt } = getQueryParams();

const AuthenticationPage = props => {

    const classes = useStyles();
    const tooltipText = "You will use this User ID as your log in for additional accounts you may have for Life Insurance, Long-Term Care, Vitality, and/or Mutual Fund IRA/Roth IRA.";
    const titleText = "Incorrect User ID / password combination"
    const errorText = "Please note that your account will be locked after too many failed login attempts."
    const [isUnderstand, setisUnderstand] = useState(false)

    //code added for PDCO appid setpreferences
    const checkisUnderstand = (value) => {      
        setisUnderstand(true); 
        props.setPaperlessTCModalOpen(false);     
    };

    const paperlessModalOpen = (event) => { 
        event && event.preventDefault()     
        props.setPaperlessTCModalOpen(true);
    };

    const paperlessModalClose = () => {
        props.setPaperlessTCModalOpen(false);
    };


    const [allValues, setAllValues] = useState({
        passwordVisible: false,
        termsAndConditions: false
    });
    
    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
        scrollToTop();
    }, [])

    const handleShow = (name) => {
        setAllValues({ ...allValues, [name]: !allValues[name] })
    }

    const changeHandler = (name, value) => {
         //code added to check for checkbox for gopaperless
         let newFormValues = null;
         if (name === 'goPaperless') {
             newFormValues = { ...props.formValues, [name]: value, "notGoPaperless": false };
         }
         else if (name === 'notGoPaperless') {
             newFormValues = { ...props.formValues, [name]: value, "goPaperless": false };
         }
         else {
             newFormValues = { ...props.formValues, [name]: value };
         }
       // const newFormValues = { ...props.formValues, [name]: value };
        props.setFormValues(newFormValues)
        props.setErrorState(validate(newFormValues, name, props.errorState, false, 4, null, true));
    }

    const tooltipTextElectronicDelivery = "Not all communications, notices and disclosures, are currently available to be delivered electronically, so you may still receive some paper mail until enhancements are made."
    
    return (
        <Grid container spacing={0}>
            {
                props.systemErrorCount > 0 &&
                    <EncounteredError systemErrorCount={props.systemErrorCount}/>
            }
            {
                ((props.authErrorCount > 0 || props.isamPasswordError)) &&
                <Grid id="authentication-error" item xs={12} md={9} className={classes.gridSeparation}>
                    <MessageCard icon={<Caution color="#1E212F" fontsize="30px" style={{ verticalAlign: '-webkit-baseline-middle' }} />} variant={MESSAGE_VARIANT.PRIMARY_WARNING} titleText={titleText}>
                        {errorText}
                    </MessageCard>
                </Grid>
            }

            <Grid item xs={12} md={9} className={[classes.flexDisplay, classes.gridSeparation]}>
                <span className={classes.subheaderStyles}>The User ID you create is your login for all accounts.</span>
                <Tooltip title={tooltipText} bubbleWidth={200}
                    customStyle={{
                        rootStyle: {
                            paddingLeft: "5px"
                        }
                    }}
                ><Info1 color="#282B3E"/>
                </Tooltip>
            </Grid>
            <Grid item md={9} xs={12} className={"gridSeparation1 myinput"+(props.errorState.authUserId.isError && !props.errorState.authUserId.warning ? " error" : "")}>
                <label className={classes.labelStyle} >
                    User ID
                </label>
                <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                    id="authUserId"
                    value={props.formValues.authUserId}
                    onKeyDown={doNothing}
                    onChange={e => changeHandler("authUserId", e)}
                    onBlur={()=> props.setErrorState({...props.errorState, authUserId: { ...props.errorState.authUserId, warning: false }})}
                    required={true}
                    placeholder="Enter your User ID"
                    customStyle={{
                        rootStyle: {
                            width: "100%",

                        },
                        inputStyle: {
                            padding: '0px'
                        }
                    }}
                    name="authUserId" />

                    {
                        props.errorState.authUserId.isError && 
                        <InputError errorState={props.errorState} inputId="authUserId" showIcon={true}/>
                    }
            </Grid>
            <Grid item md={9} xs={12} className={"gridSeparation1 myinput"+(props.errorState.authPassword.isError && !props.errorState.authPassword.warning ? " error" : "")}>
                <label className={classes.labelStyle} >
                    Password
                </label>
                <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                    id="authPassword"
                    type={allValues.passwordVisible ? "text" : "password"}
                    required={true}
                    placeholder="Enter your password"
                    value={props.formValues.authPassword}
                    onKeyDown={doNothing}
                    className={classes.InLine}
                    onChange={e => changeHandler("authPassword", e)}
                    onBlur={()=> props.setErrorState({...props.errorState, authPassword: { ...props.errorState.authPassword, warning: false }})}
                    customStyle={{
                        rootStyle: {
                            width: "100%"

                        },
                        inputStyle: {
                            padding: '0px'
                        }
                    }}
                    name="authPassword" 
                    isIconClickable={true}
                    icon={<ActionButton
                            ariaLabel="Edit"
                            icon={allValues.passwordVisible ? <Eye2 color="#282B3E" />
                                : <Eye1 color="#282B3E" />
                            }
                            onClick={e => handleShow("passwordVisible")}
                        />}
                    />            
                {
                    props.errorState.authPassword.isError && 
                        <InputError errorState={props.errorState} inputId="authPassword" showIcon={true}/>
                }

            </Grid>
            
            <Grid item xs={12} style={{marginBottom: "20px"}}>
                <Button
                    name="AuthenticateButton"
                    id="AuthenticateButton"
                    onClick={e => props.onClick(4)}
                    disabled={props.isAwaitingApiCall}
                    saving={props.isAwaitingApiCall}>Log In</Button>
                {/* {props.isAwaitingApiCall && <div className={classes.inlineDisplay}>
                    <div className={classes.inlineDisplay2}>
                        <Progress isFloating={false} customStyle={{
                            spinnerStyle: {
                                position: "relative",
                                height: "40px",
                                width: "40px"
                            }
                        }} />
                    </div>
                    <p className={classes.loadingText}>Please wait while we process your information</p>
                </div>} */}
            </Grid>
            <Grid item md={9} xs={12}>
                <span className={classes.footnoteText}><b>Please note:</b> If you have forgotten your account or password, you can recover them via 'Sign in' on <a className={classes.linkStyles} rel="noopener noreferrer" target="_blank" href="https://johnhancock.com">JohnHancock.com</a></span>
            </Grid>

            <Modal isOpen={props.isPaperlessTCModalOpen} onClose={paperlessModalClose} customStyle={{
                 modalStyle: {
                    height: 'auto',
                    width: '720px',
                    media: [{
                        maximum: BREAKPOINTS.PHONE_PORTRAIT_MED,
                        width: '85%'
                    }]
                }
            }} ariaLabel="Timeout Modal" ariaLabelledBy="heading" ariaDescribedBy="content">
                <ElectronicTCModalPage />
                <Button id="IUnderstandButton"
                        data-testid="UserInfo-IUnderstandButton"
                        name="IUnderstandButton"
                        customStyle={{
                            buttonStyle: {
                                width: "150px",
                                height: "60px"
                            }
                        }} disabled={props.isAwaitingApiCall} onClick= { e => checkisUnderstand(e)}><div className={classes.confirmTextStyle}>I understand</div></Button>                 

            </Modal>  
        </Grid>
    );
};

export default AuthenticationPage;