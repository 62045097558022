import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Eye1, Eye2 } from '@manulife/mux-cds-icons';
import { TextInput, INPUT_VARIANT, ActionButton } from '@manulife/mux';
import Utility, { doNothing } from '../../utils/utility';
import { formatPhoneNumberIntl} from 'react-phone-number-input'
const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "16px",
        color: "#000000",
        lineHeight: "26px"
    },
    textStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#000000"
    },
    InLine: {
        display: displayStyle,
    },
    InLineLabel: {
        fontWeight: "500",
        display: displayStyle,
        fontSize: "16px",
        lineHeight: "26px",
        fontFamily: font,
        paddingRight: "5px",
        color: "#000000"
    },
    showIcon: {
        display: displayStyle,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    headerStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "32px",
        lineHeight: "42px",
        fontStyle: "normal",
        color: "#282B3E"
    }
}))

const getDateofbirth = (day, month, year) => {
    if(!day) return "";
    let newDay;
    let newMonth;
    let newYear = year.substr(2)
    if (day < 10)
        newDay = "0" + day;
    else
        newDay = day;
    if (month < 10)
        newMonth = "0" + month;
    else
        newMonth = month;
    return (newMonth + "/" + newDay + "/" + newYear)
}

const getSSN = (ssn) => {
    let pre = ssn.substr(0, 2)
    let suff = ssn.substr(2)
    let newSsn = pre + "-" + suff
    return newSsn
}

/* const getPhoneNumber = (phnNumber,countryCallingCode) => {
    let phone = phnNumber.replace(/[^0-9 ]/g, "")
    let clen=countryCallingCode.length;
    phone = phone.substr(clen)
    let formattedPhone = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6)
    console.log("formattedPhone",formattedPhone);
    return formattedPhone
}
 */
const ConfirmationModalPage = props => {
    console.log("props",props);
    const {nationalPhoneNumber, countryCode}=props;
    const formatedPhoneNumber=Utility.formatPhoneNumberWithCountryCode(countryCode, nationalPhoneNumber);
    const classes = useStyles();
    const [allValues, setAllValues] = useState({
        ssnVisible: false,
    });
    const handleShow = (name) => {
        setAllValues({ ...allValues, [name]: !allValues[name] })
        //
    }

    return (<>
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.gridSeparation}>
                <span id="heading" className={classes.headerStyles}>Please confirm your information below:</span>
            </Grid>
            <Grid item md={9} xs={12}>
                <label className={classes.labelStyle}>
                    First name:
                </label>
            </Grid>
            <Grid item md={9} xs={12} className={classes.gridSeparation}>
                <label className={classes.textStyles}>
                    {props.formValues.firstName}
                </label>
            </Grid>
            <Grid item md={9} xs={12}>
                <label className={classes.labelStyle}>
                    Last name:
                </label>
            </Grid>
            <Grid item md={9} xs={12} className={classes.gridSeparation}>
                <label className={classes.textStyles}>
                    {props.formValues.lastName}
                </label>
            </Grid>
            <Grid item md={9} xs={12}>
                <label className={classes.labelStyle}>
                    Date of birth:
                </label>
            </Grid>
            <Grid item md={9} xs={12} className={classes.gridSeparation}>
                <label className={classes.textStyles}>
                    {getDateofbirth(props.formValues.day, props.formValues.month, props.formValues.year)}
                </label>
            </Grid>
            <Grid item md={9} xs={12} style={{ marginBottom: "-12px" }}>
                <label className={classes.labelStyle}>
                    Last 6 digits of your social security number:
                </label>
            </Grid>
            <Grid item md={9} xs={12} style={{ marginBottom: "12px" }}>
                <p className={classes.InLineLabel}>XXX-</p>
                <div className={classes.InLine}>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="last6DigitSSN"
                        type={allValues.ssnVisible ? "text" : "password"}
                        value={getSSN(props.formValues.lastSixDigitSSN)}
                        onKeyDown={doNothing}
                        customStyle={{
                            rootStyle: {
                                width: "75px",
                            },
                            inputStyle: {
                                borderBottomWidth: "0px"
                            }
                        }}
                        name="last6DigitSSN" disabled />
                </div>
                <ActionButton
                    ariaLabel="Edit"
                    icon={allValues.ssnVisible ? <Eye2 color="#282B3E" />
                        : <Eye1 color="#282B3E" />
                    }
                    onClick={e => handleShow("ssnVisible")}
                />
            </Grid>
            <Grid item md={9} xs={12}>
                <label className={classes.labelStyle}>
                    Email address:
                </label>
            </Grid>
            <Grid item md={9} xs={12} className={classes.gridSeparation}>
                <label className={classes.textStyles}>
                    {props.formValues.emailAddress}
                </label>
            </Grid>
            <Grid item md={9} xs={12}>
                <label className={classes.labelStyle}>
                    Phone number:
                </label>
            </Grid>
            <Grid item md={9} xs={12} className={classes.gridSeparation}>
                <label className={classes.textStyles}>
                {formatedPhoneNumber}
                </label>
            </Grid>
        </Grid>
    </>
    );
};

export default ConfirmationModalPage;